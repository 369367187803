import React, { useState } from "react";
import Auto from "../../../../assets/images/Auto.png";
import Auth from "../../../../assets/images/Auth.png";
import Unauth from "../../../../assets/images/Unauth.png";
import allApi from "../../../../api/allApi";

function RadiusConfiguration() {
  const [assignedPorts, setAssignedPorts] = useState({});
  const [servers, setServers] = useState([
    // { id: 1, name: "Noida", ports: 12 },
    { id: 2, name: "Gurugram", ports: 24 }
  ]);
  const portFields = [
    {
        portId: 1,
        portControlMode: 1
    },
];


const portConfig = async () => {
  try {
    const api = new allApi();

    const payload = Object.entries(assignedPorts).flatMap(([serverId, ports]) =>
      Object.entries(ports)
        .filter(([portNumber, config]) => config) // Only include selected ports
        .map(([portNumber, config]) => ({
          portId: parseInt(portNumber, 10), // Port number
          portControlMode: config.portControlMode, // Control mode
        }))
    );

    if (payload.length === 0) {
      console.log("No ports selected for configuration");
      return;
    }

    const response = await api.postRequest("api/wired/conf/radiusPortConfig","",payload);
    if (response) {
      console.log("Port configuration done successfully", response);
    }
  } catch (error) {
    console.error("Error in port configuration", error);
  }
};



const togglePort = (serverId, portNumber) => {
  setAssignedPorts((prev) => ({
    ...prev,
    [serverId]: {
      ...prev[serverId],
      [portNumber]: prev[serverId]?.[portNumber]
        ? null // Deselect port
        : { portControlMode: 1 }, // Default control mode when selecting
    },
  }));
};


const handleSelectAllPorts = (serverId, totalPorts) => {
  setAssignedPorts((prev) => {
    const newState = { ...prev };
    if (!newState[serverId]) newState[serverId] = {};

    const allSelected = Object.keys(newState[serverId]).length === totalPorts;

    for (let i = 1; i <= totalPorts; i++) {
      newState[serverId][i] = allSelected ? null : { portControlMode: 1 };
    }
    return newState;
  });
};

  return (
    <div>
      <div className="tab-form">
        <div className="row mb-4">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong className="radius-help">
                RADIUS server settings are shown here.
                <span data-bs-toggle="modal" data-bs-target="#radiusHelp"><i className="fa fa-question-circle-o" aria-hidden="true"></i></span>
              </strong>              
            </div>
          </div>
        </div>
        <div className="mt-3">
      {servers.map((server) => (
        <div key={server.id} className="radius-device">
          <div className="radius-device-title">
            <h3>{server.name} - {server.ports} Port</h3>
          </div>
          <div className="radius-device-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="switch-box">
                  <strong>{server.name}</strong>
                  <label className="ice-switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="router-port left mt-3">
                  <div className="mxw-1260">
                    <div className="polygon"></div>
                    <div className="port-box">
                      <ul className={`port${server.ports}`}>
                        {[...Array(server.ports)].map((_, index) => {
                          const portNumber = index + 1;
                          return (
                            <li
                              key={portNumber}
                              className={
                                assignedPorts[server.id]?.[portNumber] ? "assign" : "unassign"
                              }
                              onClick={() => togglePort(server.id, portNumber)}
                            >
                              {portNumber}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="radius-device-footer schedule-btn">
            <button
              type="button"
              className="btn btn-outline"
              data-bs-toggle="modal"
              data-bs-target="#radiusPortAuth"
            >
              Port Authentication Mode
            </button>
            <button
              type="button"
              className="btn btn-outline"
              onClick={() => handleSelectAllPorts(server.id, server.ports)}
            >
              Select All
            </button>
          </div>
        </div>
      ))}
    </div>
      </div>
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">
          Cancel
        </button>
        <button type="submit" className="text-btn primary-btn" onClick={()=>portConfig()}>
          Save
        </button>
      </div>

      <div className="modal fade radius-help-modal" id="radiusHelp" tabindex="-1" aria-labelledby="radiusHelp" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="radiusHelpLabel">RADIUS Configuration</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p><strong>Configure the 802.1x port authentication for the switch Authentication mode would be one of the following:</strong></p>
              <ul>
                <li>
                  <span>
                    <img src={Auto} alt="Unauthorized" />
                  </span>
                  <p>
                    <strong>Auto</strong>
                    A device connected to the port must authenticate to access the network.
                  </p>
                </li>
                <li>
                  <span>
                    <img src={Auth} alt="Unauthorized" />
                  </span>
                  <p>
                    <strong>Authorized</strong>
                    A device connected to the port has access to the network without authentication.
                  </p>
                </li>
                <li>
                  <span>
                    <img src={Unauth} alt="Unauthorized" />
                  </span>
                  <p>
                    <strong>Unauthorized</strong>
                    A device connected to the port does not have access to any network.
                  </p>
                </li>
              </ul>
            </div>            
          </div>
        </div>
      </div>


      <div className="modal fade radius-help-modal" id="radiusPortAuth" tabindex="-1" aria-labelledby="radiusPortAuth" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered medium">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="radiusPortAuthLabel">Device Mode</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p><strong>Select Port Authentication Mode</strong></p>
              <ul>
                <li>
                  <span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="auth"/>
                    </div>
                  </span>
                  <p>
                    <strong>Auto</strong>
                    A device connected to the port must authenticate to access the network.
                  </p>
                </li>
                <li>
                  <span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="auth"/>
                    </div>
                  </span>
                  <p>
                    <strong>Authorized</strong>
                    A device connected to the port has access to the network without authentication.
                  </p>
                </li>
                <li>
                  <span>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="auth"/>
                    </div>
                  </span>
                  <p>
                    <strong>Unauthorized</strong>
                    A device connected to the port does not have access to any network.
                  </p>
                </li>
              </ul>
              <div className="modal-footer pb-0">
                <button type="button" className="btn btn-outline" data-bs-dismiss="modal">Cancel</button>
                <button className="text-btn primary-btn" data-bs-dismiss="modal">Save and Continue</button>
              </div>
            </div>            
          </div>
        </div>
      </div>


      {/* <div className="customModal confirm-modal">
        <div className="modal-dialog modal-dialog-centered small">
          <div className="modal-content inc-modal">
            <div className="modal-body">              
              <p>To avoid losing access to the Internet, make sure that all uplink ports are set to Authorized.</p>              
              <div className="modal-footer">
                <button className="text-btn primary-btn">OK</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default RadiusConfiguration;