import React, { useState, useEffect } from "react";
import allApi from "../../../api/allApi";
import UserAddModal from "../common/UserAddModal";
import EditUserModal from "../common/EditUserModal";
import errorIcon from "../../../assets/images/error-2.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import successIcon from "../../../assets/images/success_lg.png";
import RoleInfo from "../common/RoleInfo";
import Loader from "../common/Loader";
function UserManagement() {
  const [roleName, setRoleName] = useState("");
  const [permissionName, setPermissionName] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [showUserPopUp, setShowUserPopUp] = useState(false);
  const [users, setUsers] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState([]);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [activeTab, setActiveTab] = useState("user");
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionDescription, setPermissionDescription] = useState("");
  const [allPermission, setAllPermission] = useState("");
  const [allFeatures, setAllFeatures] = useState("");
  const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
  const [modalContent, setModalContent] = useState({
    title: "",
    message: "User has been successfully updated.",
  });
  const [aboveSelected, setAboveSelected] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showRoleInfo, setShowRoleInfo] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxChecked, setIndividualCheckboxChecked] = useState(
    {}
  );
  const [selectAllbox, setSelectAllbox] = useState(false);
  const [individualboxChecked, setIndividualboxChecked] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  
  useEffect(() => {
    fetchUsers();
    fetchPermissions();
    fetchPermissionsForRoles();
    handleFeatureWisePermission();
  }, []);

  useEffect(() => {
    if (selectedRole && selectedPermissions.length > 0) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [selectedRole, selectedPermissions]);

  const fetchUsers = async () => {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      let response = await api.getRequest(`api/user/findAllUsers`);
      if (response && Array.isArray(response)) {
        response = response.reverse();
        setUsers(response);
      }
      await fetchRoles();
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };
  const fetchRoles = async () => {
    setLoading(true);
    setRefreshing(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/user/findAllRoles`);
      setRoles(response);
      setLoading(false);
      setRefreshing(false);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const handleEditUser = async (userId) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/user/${userId}`);
      if (response) {
        setSelectedUser(response);
        setShowEditModal(true);
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchPermissions = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/user/findAllPermissions");
      setPermissions(response);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleDeleteUser = async (userToDelete) => {
    try {
      const api = new allApi(); // Ensure allApi is instantiated correctly
      const payload = userToDelete.map((user) => ({
        emails: [user.email],
      }));
      console.log("email",payload);
      const response = await api.deleteRequest('api/user/delete', payload);

      if (response) {
        setUsers((prevListings) =>
          prevListings.filter(
            (user) =>
              !userToDelete.some((toDelete) => toDelete.email === user.email)
          )
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage('You do not have permission to delete this user.');
      } else {
        console.error('Error deleting user:', error);
        setErrorMessage('An error occurred while deleting the user. Please try again later.');
      }
    }
  };


  const handleAddRole = async () => {
    if (!roleName) {
      setErrorMessage("Role Name cannot be empty");
      setShowErrorModal(true);
      return;
    }
    try {
      const api = new allApi();
      const payload = {
        name: roleName,
        above: aboveSelected,
        below: !aboveSelected,
        role_name: selectedRole,
      };
      const response = await api.postRequest(`api/user/add_role`, "", payload);
      const newRole = response.data.role;
      setRoleName("");
      fetchUsers();
      fetchRoles();
      const modal = document.getElementById("addRole");
      if (modal) {
        const bootstrapModal = new Modal(modal);
        bootstrapModal.hide();
      }
      setShowSuccessModal(true);
      await fetchPermissionsForRoles();
    } catch (error) {
      console.error("Error adding role:", error);
      showSuccessPopUp({ message: "Role has been added" });
    }
  };

  const handleAddPermission = async () => {
    if (!permissionName) {
      setErrorMessage("Permission Name cannot be empty");
      setShowErrorModal(true);
      return;
    }
    try {
      const api = new allApi();
      const response = await api.postRequest("api/user/add_permission", "", {
        name: permissionName,
        description: permissionDescription,
      });
      setPermissionName("");
      fetchPermissions();
    } catch (error) {
      console.error("Error adding permission:", error);
    }
  };

  const handleAssignPermission = async () => {
    if (!selectedRole || selectedPermissions.length === 0) {
      setErrorMessage("Role and at least one permission must be selected");
      setShowErrorModal(true);
      return;
    }
    try {
      const api = new allApi();
      const selectedPermissionNames = selectedPermissions.map(
        (permission) => permission.name
      );

      const payload = {
        role: selectedRole,
        permission: selectedPermissionNames,
      };

      const response = await api.postRequest(
        `api/user/assign_permission_to_role`,
        "",
        payload
      );
      setSelectedRole("");
      setSelectedPermissions([]);
      fetchPermissionsForRoles();
      handleFeatureWisePermission();
      setModalContent({
        title: "Success",
        message: "Permissions successfully assigned to the role.",
      });
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error assigning permission to role:", error);
    }
  };

  const fetchPermissionsForRoles = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/user/permissionsForRoles");
      setAllPermission(response);
    } catch (error) {
      console.error("Error fetching permissions for roles:", error);
    }
  };

  const handleFeatureWisePermission = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/user/featurewisePermissions");
      if (Array.isArray(response)) {
        setAllFeatures(response);
      } else {
        console.error("Unexpected response format:", response);
        setAllFeatures([]);
      }
    } catch (error) {
      console.error("Error fetching permissions for roles:", error);
      setAllFeatures([]);
    }
  };

  const handleDeleteAssignPermissionRole = async (roleId) => {
    try {
      const api = new allApi();
      const response = await api.deleteRequest(`api/user/delete_role/${roleId}`);

      if (response.status === 200) {
        fetchRoles();
        setSelectedRole();
      } else {

        console.error("Failed to delete role:", response.statusText);
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
      console.error("Error deleting permission from role:", error.message);
    }
  };

  const handlePermissionCheckboxChange = (permission) => {
    const isChecked = selectedPermissions.some(
      (selectedPermission) => selectedPermission.id === permission.id
    );
    if (isChecked) {
      setSelectedPermissions(
        selectedPermissions.filter((p) => p.id !== permission.id)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, permission]);
    }
  };

  const handleAddButtonClick = () => {
    setShowUserPopUp(true);
  };

  const handleUserClose = () => {
    setShowUserPopUp(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedUser(null);
  };

  const handleDeleteButtonClick = (email) => {
    // Find the selected user by userId
    const userToDelete = users.find(
      (user) => user.email === email
    );

    if (userToDelete) {
      handleDeleteUser([userToDelete]); // Pass an array with only the single user to delete
    }
  };

  const handleDeleteConfirmation = () => {
    if (userToDelete) {
      handleDeleteUser(userToDelete);
      setUserToDelete(null);
    }
  };
  const handleDeleteRoleConfirmation = () => {
    if (roleToDelete) {
      handleDeleteAssignPermissionRole(roleToDelete);
      setUserToDelete(null);
    }
  };
  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
  };

  const handleSelectAllCheckbox = () => {
    setSelectAllChecked((prev) => !prev);
    const newCheckboxState = {};
    users.forEach((userData) => {
      newCheckboxState[userData.user.userId] = !selectAllChecked;
    });
    setIndividualCheckboxChecked(newCheckboxState);
  };

  const handleIndividualCheckboxChange = (userId) => {
    setIndividualCheckboxChecked((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };
  const handleSelectAllbox = () => {
    const newboxState = {};
    allPermission.forEach((role) => {
      newboxState[role.role] = !selectAllbox;
    });
    setIndividualboxChecked(newboxState);
    setSelectAllbox((prev) => !prev);
  };

  const handleIndividualboxChange = (roleId) => {
    setIndividualboxChecked((prev) => ({
      ...prev,
      [roleId]: !prev[roleId],
    }));
  };

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  const showSuccessPopUp = (response) => {
    setModalContent({ title: "Success", message: response.message });
    setShowSuccessModal(true);
  };
  const handleSuccessAddRole = (message) => {
    setModalContent({ title: "Success", message });
    setShowSuccessModal(true);
  };

  const handleMoreInfoClick = (e) => {
    e.preventDefault();
    setShowRoleInfo(true);

  };
  const resetForm = () => {
    setRoleName("");
    setSelectedRole("");
  }

  const handleCloseRoleInfo = () => {
    resetForm();
    setShowRoleInfo(false);
  };
  const handleCloseMoreInfo = () => {
    setShowRoleInfo(true);
  }
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const totalFilteredUsers = users.length;
  const currentUsers = users.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  const totalPages = Math.ceil(totalFilteredUsers / usersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };


  const getPageNumbers = () => {
    const range = 1;
    const start = Math.max(1, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);

    return [...Array(end - start + 1).keys()].map((i) => start + i);
  };

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <ul className="nav nav-underline" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link um active"
                id="user-tab"
                data-bs-toggle="tab"
                data-bs-target="#user-tab-pane"
                type="button"
                role="tab"
                aria-controls="user-tab-pane"
                aria-selected="true"
              >
                Users Account
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link rm"
                id="role-tab"
                data-bs-toggle="tab"
                data-bs-target="#role-tab-pane"
                type="button"
                role="tab"
                aria-controls="role-tab-pane"
                aria-selected="false"
              >
                Roles
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link pm"
                id="permission-tab"
                data-bs-toggle="tab"
                data-bs-target="#permission-tab-pane"
                type="button"
                role="tab"
                aria-controls="permission-tab-pane"
                aria-selected="false"
              >
                Permissions
              </button>
            </li>
          </ul>
          <div className="inc-card-button user-button">
            <button
              className="text-btn primary-btn"
              onClick={handleAddButtonClick}
            >
              Add User
            </button>
            <button
              className="icon-btn"
              onClick={() => handleDeleteUser()}
            >
              <i
                className="fa fa-trash"
                aria-hidden="true"
              ></i>
            </button>
            <button
              className="icon-btn"
              title="Refresh"
              onClick={() => fetchUsers()}
            >
              {refreshing ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-refresh" aria-hidden="true"></i>
              )}
            </button>
          </div>
          <div className="inc-card-button role-button">
            <button
              className="text-btn primary-btn"
              data-bs-toggle="modal"
              data-bs-target="#addRole"
            >
              Add Role
            </button>
            <button className="icon-btn"
              title="Delete"
              data-bs-toggle="modal"
              data-bs-target="#noDeviceDelete"
              onClick={() => {
                handleDeleteAssignPermissionRole();
                fetchPermissionsForRoles();
              }}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
            <button
              className="icon-btn"
              title="Refresh"
              onClick={() => fetchRoles()}
            >
              {refreshing ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-refresh" aria-hidden="true"></i>
              )}
            </button>
          </div>
        </div>

        <div className="inc-card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="user-tab-pane"
              role="tabpanel"
              aria-labelledby="user-tab"
              tabIndex="0"
            >
              <div className="tab-pagination">
                {/* ====== USER TAB ====== */}
                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={() => handleSelectAllCheckbox()}
                            />
                            <span className="indicator"></span>
                          </label>
                        </th>
                        <th>Username</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th className="w200">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        currentUsers.map((userData) => (
                          <tr key={userData.user.email}>
                            <td>
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  checked={
                                    individualCheckboxChecked[
                                    userData.user.email
                                    ] || false
                                  }
                                  onChange={() =>
                                    handleIndividualCheckboxChange(
                                      userData.user.email
                                    )
                                  }
                                />
                                <span className="indicator"></span>
                              </label>
                            </td>
                            <td>{userData.user.username || "N/A"}</td>
                            <td>{userData.user.firstname || "N/A"}</td>
                            <td>{userData.user.lastname || "N/A"}</td>
                            <td>{userData.user.email || "N/A"}</td>
                            <td>{userData.user.role.name || "N/A"}</td>
                            <td className="w200">
                              <div className="button-group">
                                <button
                                  title="Edit"
                                  onClick={() =>
                                    handleEditUser(userData.user.userId)
                                  }
                                >
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <button
                                  title="Delete"
                                  data-bs-toggle="modal"
                                  data-bs-target="#noDeviceDelete"
                                  onClick={() =>
                                    handleDeleteButtonClick(userData.user.email)
                                  }
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-container">
                  <div className="pagination-info">
                    <p>
                      Showing{" "}
                      {Math.min(currentPage * usersPerPage, totalFilteredUsers)}{" "}
                      of {totalFilteredUsers} records
                    </p>
                  </div>
                  <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 1} >
                      Previous
                    </button>
                    {getPageNumbers().map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageClick(page)}
                        className={currentPage === page ? "active" : ""}
                      >
                        {page}
                      </button>
                    ))}
                    <button onClick={handleNextPage} disabled={currentPage === totalPages} >
                      Next
                    </button>
                    <button onClick={handleLastPage} disabled={currentPage === totalPages}>
                      Last Page
                    </button>
                  </div>
                </div>
              </div>
              {/* ====== USER TAB END ====== */}
            </div>
            <div
              className="tab-pane fade"
              id="role-tab-pane"
              role="tabpanel"
              aria-labelledby="role-tab"
              tabIndex="0"
            >
              <div className="inc-card-table">
                <table className="full-table m-head inc-role-table">
                  <thead>
                    <tr>
                      <th>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            checked={selectAllbox}
                            onChange={() => handleSelectAllbox()}
                          />{" "}
                          <span className="indicator"></span>
                        </label>
                      </th>
                      <th>Role Name</th>
                      <th>Permission Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="6">
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      allPermission &&
                      allPermission.map((role) => (
                        <tr key={role.role_id}>
                          <td>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={
                                  individualboxChecked[role.role] || false
                                }
                                onChange={() =>
                                  handleIndividualboxChange(role.role)
                                }
                              />{" "}
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>{role.role}</td>
                          <td>{role.permissions}</td>
                          <td className="w200">
                            <div className="button-group">
                              <button title="Edit">
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </button>

                              <button
                                title="Delete"
                                data-bs-toggle="modal"
                                data-bs-target="#noDeviceDelete"
                                onClick={() => {
                                  handleDeleteAssignPermissionRole(
                                    role.roleId
                                  );
                                  fetchPermissionsForRoles();
                                }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="permission-tab-pane"
              role="tabpanel"
              aria-labelledby="permission-tab"
              tabIndex="0"
            >
              <div className="per-role-select">
                <select
                  className="form-select"
                  id="role"
                  name="role"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                >
                  <option value="">Select Role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="permission-wrapper">
                <div className="permission-name">
                  <ul id="nav-tab" role="tablist">
                    {Array.isArray(allFeatures) &&
                      allFeatures.map((feature, index) => (
                        <li
                          key={feature.feature_id}
                          id={`nav-${feature.feature_id}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#nav-${feature.feature_id}`}
                          type="button"
                          role="tab"
                          aria-controls={`nav-${feature.feature_id}`}
                          aria-selected="true"
                          className={index === 0 ? "active" : ""}
                        >
                          {/* <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label> */}
                          {feature.feature_name}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="permission-type" id="nav-tabContent">
                  {Array.isArray(allFeatures) &&
                    allFeatures.map((feature, index) => (
                      <div
                        key={feature.feature_id}
                        className={`tab-pane fade ${index === 0 ? "show active" : ""
                          }`}
                        id={`nav-${feature.feature_id}`}
                        role="tabpanel"
                        aria-labelledby={`nav-${feature.feature_id}-tab`}
                      >
                        <ul>
                          {feature.permissions.map((permission) => (
                            <li key={permission.id}>
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  checked={selectedPermissions.includes(
                                    permission
                                  )}
                                  onChange={() =>
                                    handlePermissionCheckboxChange(permission)
                                  }
                                />
                                <span className="indicator"></span>
                              </label>
                              {permission.description}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
              <div className="permission-button mt-3">
                <button
                  className="text-btn primary-btn"
                  onClick={handleAssignPermission}
                  disabled={isSaveDisabled}
                >
                  Save Permission
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ROLE Modal --> */}
        <div
          className="modal fade customModal"
          id="addRole"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="addRoleLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addUserLabel">
                  New Role
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseRoleInfo}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="roleName" className="form-label">
                      Role Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="roleName"
                      value={roleName}
                      onChange={(e) => setRoleName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3 d-flex justify-content-between">

                    <div className="radio-buttons">
                      Position this role {"  "}
                      <input
                        type="radio"
                        id="above"
                        name="rolePosition"
                        checked={aboveSelected}
                        onChange={() => setAboveSelected(true)}
                      />
                      <label htmlFor="above">Above</label>
                      or {" "}
                      <input
                        type="radio"
                        id="below"
                        name="rolePosition"
                        checked={!aboveSelected}
                        onChange={() => setAboveSelected(false)}
                      />
                      <label htmlFor="below">Below</label>
                      the role
                    </div>
                    <a
                      href="#"
                      data-bs-dismiss="modal"
                      onClick={handleMoreInfoClick}
                    >
                      More Info
                    </a>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="roleAbove" className="form-label">
                      Role List<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="roleAbove"
                      value={selectedRole}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    >
                      <option value="">Select Role</option>

                      {roles.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseRoleInfo}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={handleAddRole}
                  data-bs-dismiss="modal"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Permission Modal --> */}
        <div
          className="modal fade customModal"
          id="addPermission"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="addPermissionLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addUserLabel">
                  New Permission
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <label htmlFor="startIp" className="col-form-label">
                        Permission Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={permissionName}
                        onChange={(e) => setPermissionName(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={handleAddPermission}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRoleInfo && (
        <RoleInfo
          showPopUp={showRoleInfo}
          handleClose={handleCloseRoleInfo}
          headerMessage1="Role Information"
        />
      )}
      <UserAddModal
        showPopUp={showUserPopUp}
        handleClose={handleUserClose}
        headerMessage1="New User"
        onSuccess={handleSuccessAddRole}
      />
      <EditUserModal
        showPopUp={showEditModal}
        handleClose={() => setShowEditModal(false)}
        user={selectedUser}
        showSuccessPopUp={showSuccessPopUp}
      />
      <div
        className={`modal fade`}
        id="noDeviceDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure want to delete?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => handleDeleteButtonClick()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade`}
        id="roleDelete"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure want to delete Role?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={handleDeleteRoleConfirmation}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Roles has been added successfully</p>
        <Button
          onClick={() => {
            handleSuccessModalClose();
          }}
        >
          OK
        </Button>
      </Modal>
      <Modal show={showSuccessModal} onHide={handleSuccessModalClose} centered>
        <Modal.Body className="edge-error-msg">
          <img src={successIcon} alt="Success" />
          <h2>{modalContent.title}</h2>
          <p>{modalContent.message}</p>
          <Button
            variant="primary"
            onClick={() => {
              handleSuccessModalClose();
              fetchUsers();
            }}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UserManagement;
