import React, { useState, useEffect } from "react";

function ReportProfile({ onUpdate, reportData = {} }) {
  const [reportName, setReportName] = useState(reportData.reportName || "");
  const [description, setDescription] = useState(reportData.description || "");
  const [emailRecipients, setEmailRecipients] = useState(reportData.emailRecipients || "");
  const [timePeriod, setTimePeriod] = useState(reportData.timePeriod || "30d");
  const [reportType, setReportType] = useState(reportData.reportType || "Custom Report");
  const [executionPeriod, setExecutionPeriod] = useState(reportData.executionPeriod || "Run Once");

  useEffect(() => {
    const data = {
      reportName,
      description,
      emailRecipients: emailRecipients.split(",").map((email) => email.trim()),
      timePeriod,
      reportType,
      executionPeriod,
    };
    onUpdate(data);
  }, [
    reportName,
    description,
    emailRecipients,
    timePeriod,
    reportType,
    executionPeriod,
    onUpdate,
  ]);

  return (
    <div>
      <h4>Report Profile</h4>
      <p>
        Provide report name, notes and define the profile for generation of
        report as once recursive.
      </p>
      <div className="row mb-35">
        <div className="col-lg-4">
          <label className="form-label">Report Name*</label>
          <input
            type="text"
            className="form-control"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
          />
        </div>
        <div className="col-lg-4">
          <label className="form-label">Description</label>
          <input
            type="text"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>

      <h4 className="mb-3">Report Type</h4>
      <div className="report-radio mb-3">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttype"
            id="reporttype1"
            checked={reportType === "Custom Report"}
            onChange={() => setReportType("Custom Report")}
          />
          <label className="form-check-label" htmlFor="reporttype1">
            Custom Report
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttype"
            id="reporttype2"
            checked={reportType === "Troubleshooting Report"}
            onChange={() => setReportType("Troubleshooting Report")}
          />
          <label className="form-check-label" htmlFor="reporttype2">
            Troubleshooting Report
          </label>
        </div>
      </div>

      <h4>Execution Period</h4>
      <div className="report-radio mb-35">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttime"
            id="reporttime1"
            checked={executionPeriod === "Run Once"}
            onChange={() => setExecutionPeriod("Run Once")}
          />
          <label className="form-check-label" htmlFor="reporttime1">
            Run Once
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="reporttime"
            id="reporttime2"
            checked={executionPeriod === "Scheduled"}
            onChange={() => setExecutionPeriod("Scheduled")}
          />
          <label className="form-check-label" htmlFor="reporttime2">
            Scheduled
          </label>
        </div>
      </div>

      <h4>Time Period</h4>
      <div className="row mb-35">
        <div className="col-lg-4">
          <select
            className="form-select"
            id="time"
            name="time"
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
          >
            <option value="24h">Last 24 Hours</option>
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last Month</option>
          </select>
        </div>
      </div>

      <h4>Email Address<span className="text-danger">*</span></h4> 
      <p>
        Please enter commas separated email address for all the recipients to
        whom reports should be sent.
      </p>
      <div className="row mb-35">
        <div className="col-lg-7">
          <input
            type="text"
            className="form-control"
            value={emailRecipients}
            onChange={(e) => setEmailRecipients(e.target.value)}
          />
        </div>
      </div>

      <h4 className="mb-2">Branding Logo</h4>
      <div className="report-radio mb-4">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="logo"
            id="logo1"
          />
          <label className="form-check-label" htmlFor="logo1">
            Use VAR Logo
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="logo"
            id="logo2"
          />
          <label className="form-check-label" htmlFor="logo2">
            Use Organization Logo
          </label>
        </div>
      </div>
    </div>
  );
}

export default ReportProfile;
