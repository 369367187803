import React, { useState, useEffect } from "react";
import allApi from "../../../../api/allApi";
import Loader from "../../common/Loader";

function ReportPickWidgets({ onUpdate }) {
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchWidgets();
  }, []);

  const fetchWidgets = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest("api/widget/list");
      if (response && Array.isArray(response)) {
        setWidgets(response);
        setSelectedWidgets([]);
      } else {
        setWidgets([]);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = () => {
    const newSelected =
      selectedWidgets.length !== widgets.length
        ? widgets.map((widget, index) => ({
            widgetId: widget.widget_id,
            widgetName: widget.widget_name,
            widgetDesc: widget.widget_desc,
            position: index + 1,
          }))
        : [];
    setSelectedWidgets(newSelected);
    onUpdate(newSelected);
  };

  const handleSelectIndividual = (widgetId) => {
    const widget = widgets.find((w) => w.widget_id === widgetId);
    const isSelected = selectedWidgets.some((w) => w.widgetId === widgetId);

    const updatedWidgets = isSelected
      ? selectedWidgets.filter((w) => w.widgetId !== widgetId)
      : [
          ...selectedWidgets,
          {
            widgetId,
            widgetName: widget.widget_name,
            widgetDesc: widget.widget_desc,
            position: selectedWidgets.length + 1,
          },
        ];

    setSelectedWidgets(updatedWidgets);
    onUpdate(updatedWidgets);
  };

  const isWidgetChecked = (widgetId) =>
    selectedWidgets.some((widget) => widget.widgetId === widgetId);

  const isSelectAllChecked =
    widgets.length > 0 && selectedWidgets.length === widgets.length;

  return (
    <div>
      <h4>Pick Widgets</h4>
      <p>Add one or more widgets to the report</p>

      <div className="accordion mt-4" id="accordionWidgets">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              INC Device
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              {error ? (
                <p className="text-danger">Error: {error}</p>
              ) : loading ? (
                <Loader />
              ) : (
                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={isSelectAllChecked}
                              onChange={handleSelectAll}
                              disabled={widgets.length === 0}
                            />
                            <span className="indicator"></span>
                          </label>
                        </th>
                        <th>Widget Name</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {widgets.map((widget) => (
                        <tr key={widget.widget_id}>
                          <td>
                            <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={isWidgetChecked(widget.widget_id)}
                                onChange={() =>
                                  handleSelectIndividual(widget.widget_id)
                                }
                              />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>{widget.widget_name}</td>
                          <td>{widget.widget_desc}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportPickWidgets;
