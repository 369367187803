import React, { useState, useEffect } from "react";
import Auth from "../../../../assets/images/Auth.png";
import Unauth from "../../../../assets/images/Unauth.png";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";

function WiredSettings({
  data,
  setData,
  serialNumber,
  formData,
  setFormData,
  voiceOptimization,
  setVoiceOptimization,
  videoOptimization,
  setVideoOptimization,
  onDataChange,
}) {
  const [assignedPorts, setAssignedPorts] = useState({});
  const [localFormData, setLocalFormData] = useState({
    vlanId: "",
    serialNumber: serialNumber || "",
    taggedPorts: [],
    untaggedPorts: [],
  });

  const navigate = useNavigate();
  const [portDetails, setPortDetails] = useState([]);
  const [isMacAclEnabled, setIsMacAclEnabled] = useState(false);
  const [policy, setPolicy] = useState("0");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupIP, setShowPopupIP] = useState(false);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [manualAclEntries, setManualAclEntries] = useState([]);
  const [customAclEntries, setCustomAclEntries] = useState([]);
  const [newAclEntry, setNewAclEntry] = useState({
    deviceName: "",
    mac: "",
    customConfig: "",
    allowFrom: false,
    allowTo: false,
  });
  // const [newManualAcl, setNewManualAcl] = useState({
  //   deviceName: "",
  //   mac: "",
  //   customConfig: "",
  //   allowFrom: false,
  //   allowTo: false,
  // });
  const [isManualMode, setIsManualMode] = useState(true);
  const [allowFromThisDevice, setAllowFromThisDevice] = useState(false);
  const [allowToThisDevice, setAllowToThisDevice] = useState(false);

  //temparory var
  const [assignedPortsV2, setAssignedPortsV2] = useState({});
  const [accessPortV2, setAccessPortV2] = useState({});
  const [truncatePortV2, setTruncatePortV2] = useState({});
  const [macAclPayload, setMacAclPayload] = useState(null);
  const wiredSettingPayload = {
    SerialNumber: serialNumber,
    vlanMembers: {
      apPortMembers: [],
      employeeMembers: [],
      guestMembers: [],
      lagMembers: {
        portGrp: [],
        tagged: [],
        untagged: [],
      },
      lanPortMembers: [],
      memberWirelessNetwork: [],
      portMembers: [
        {
          taggedPorts: [],
          untaggedPorts: [],
        },
      ],
    },
  };

  const handleWiredSettingsDataChange = (updatedPayloadList) => {
    onDataChange(updatedPayloadList);
  };

  const [wiredSettingPayloadList, setWiredSettingPayloadList] = useState([]);

  useEffect(() => {}, [assignedPortsV2]);

  useEffect(() => {}, [accessPortV2]);

  useEffect(() => {}, [truncatePortV2]);

  useEffect(() => {
    if (data?.vlanId) {
      fetchMacAclStatus();
    }
  }, [data, policy]);

  useEffect(() => {
    const fetchPortDetails = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest("api/vlan/get/accessPort");

        if (response && response.devices) {
          const devices = response.devices.map((device) => ({
            ...device,
            taggedPorts: device.tagged_ports,
            untaggedPorts: device.untagged_ports,
          }));
          setPortDetails(devices);
          const initialAssignedPorts = {};
          devices.forEach(({ serialNo, taggedPorts, untaggedPorts }) => {
            initialAssignedPorts[serialNo] = {};
            taggedPorts.forEach((port) => {
              initialAssignedPorts[serialNo][port] = "trunk";
            });
            untaggedPorts.forEach((port) => {
              initialAssignedPorts[serialNo][port] = "access";
            });
          });
          setAssignedPorts(initialAssignedPorts);
        }
      } catch (error) {
        console.error("Error fetching port details:", error);
      }
    };

    fetchPortDetails();
  }, []);

  const totalPortMembersCount = portDetails?.length;

  const handlePortClickV2 = (serialNo, portNumber) => {
    setAssignedPortsV2((prev) => {
      const updatedPorts = { ...prev };
      if (updatedPorts[serialNo]) {
        if (updatedPorts[serialNo].includes(portNumber)) {
          updatedPorts[serialNo] = updatedPorts[serialNo].filter(
            (port) => port !== portNumber
          );
        } else {
          updatedPorts[serialNo] = [...updatedPorts[serialNo], portNumber];
        }
      } else {
        updatedPorts[serialNo] = [portNumber];
      }
      return updatedPorts;
    });
  };

  const handlePortClick = (serialNo, portNumber) => {
    handlePortClickV2(serialNo, portNumber);

    setAssignedPorts((prev) => {
      const modelPorts = prev[serialNo] || {};
      const isSelected = !modelPorts[portNumber];
      const isTagged = modelPorts[portNumber] === "trunk";
      togglePort(portNumber, isTagged);
      return {
        ...prev,
        [serialNo]: {
          ...modelPorts,
          [portNumber]: isSelected ? "selected" : undefined,
        },
      };
    });
  };

  const handleAccessPortV2 = () => {
    const updatedPayloadList = [...wiredSettingPayloadList];
    Object.entries(assignedPortsV2).forEach(([serialNo, ports]) => {
      let existingPayload = updatedPayloadList.find(
        (payload) => payload.SerialNumber === serialNo
      );
      if (!existingPayload) {
        existingPayload = {
          SerialNumber: serialNo,
          vlanMembers: {
            apPortMembers: [],
            employeeMembers: [],
            guestMembers: [],
            lagMembers: {
              portGrp: [],
              tagged: [],
              untagged: [],
            },
            lanPortMembers: [],
            memberWirelessNetwork: [],
            portMembers: [
              {
                taggedPorts: [],
                untaggedPorts: ports,
              },
            ],
          },
        };
        updatedPayloadList.push(existingPayload);
      }
      const untaggedPorts = ports.filter((port) =>
        localFormData.untaggedPorts.includes(port)
      );
      existingPayload.vlanMembers.portMembers[0].untaggedPorts = [
        ...new Set([
          ...existingPayload.vlanMembers.portMembers[0].untaggedPorts,
          ...untaggedPorts,
        ]),
      ];
      // Remove ports from tagged ports in the payload
      existingPayload.vlanMembers.portMembers[0].taggedPorts =
        existingPayload.vlanMembers.portMembers[0].taggedPorts.filter(
          (port) => !untaggedPorts.includes(port)
        );
    });
    setWiredSettingPayloadList(updatedPayloadList);
    handleWiredSettingsDataChange(updatedPayloadList);
  };

  const handleTrunkPortV2 = () => {
    const updatedPayloadList = [...wiredSettingPayloadList];

    Object.entries(assignedPortsV2).forEach(([serialNo, ports]) => {
      let existingPayload = updatedPayloadList.find(
        (payload) => payload.SerialNumber === serialNo
      );

      if (!existingPayload) {
        existingPayload = {
          SerialNumber: serialNo,
          vlanMembers: {
            apPortMembers: [],
            employeeMembers: [],
            guestMembers: [],
            lagMembers: {
              portGrp: [],
              tagged: [],
              untagged: [],
            },
            lanPortMembers: [],
            memberWirelessNetwork: [],
            portMembers: [
              {
                taggedPorts: ports,
                untaggedPorts: [],
              },
            ],
          },
        };
        updatedPayloadList.push(existingPayload);
      }

      // Filter ports into tagged only
      const taggedPorts = ports.filter(
        (port) => !localFormData.taggedPorts.includes(port)
      );

      // Update the tagged ports
      existingPayload.vlanMembers.portMembers[0].taggedPorts = [
        ...new Set([
          ...existingPayload.vlanMembers.portMembers[0].taggedPorts,
          ...taggedPorts,
        ]),
      ];

      // Update trunk class logic for rendering
      setAssignedPorts((prev) => {
        const updatedPorts = { ...prev };
        if (!updatedPorts[serialNo]) {
          updatedPorts[serialNo] = {};
        }
        ports.forEach((port) => {
          updatedPorts[serialNo][port] = "trunk";
        });
        return updatedPorts;
      });
    });

    setWiredSettingPayloadList(updatedPayloadList);
    handleWiredSettingsDataChange(updatedPayloadList);
  };

  const handleAccessPort = () => {
    handleAccessPortV2();
    setAssignedPorts((prev) => {
      const updatedPorts = { ...prev };
      Object.entries(assignedPortsV2).forEach(([serialNo, ports]) => {
        if (!updatedPorts[serialNo]) {
          updatedPorts[serialNo] = {};
        }
        ports.forEach((port) => {
          updatedPorts[serialNo][port] = "access";
        });
      });
      return updatedPorts;
    });

    setAssignedPortsV2({});
  };

  const handleTrunkPort = () => {
    handleTrunkPortV2();
    setAssignedPortsV2({});
  };

  const togglePort = (portNumber, isTagged) => {
    setLocalFormData((prev) => {
      let updatedTaggedPorts = [...prev.taggedPorts];
      let updatedUntaggedPorts = [...prev.untaggedPorts];
      if (isTagged) {
        updatedTaggedPorts = [...new Set([...updatedTaggedPorts, portNumber])];
        updatedUntaggedPorts = updatedUntaggedPorts.filter(
          (port) => port !== portNumber
        );
      } else {
        updatedUntaggedPorts = [
          ...new Set([...updatedUntaggedPorts, portNumber]),
        ];
        updatedTaggedPorts = updatedTaggedPorts.filter(
          (port) => port !== portNumber
        );
      }

      return {
        ...prev,
        taggedPorts: updatedTaggedPorts,
        untaggedPorts: updatedUntaggedPorts,
      };
    });
  };

  const updateFormDataPorts = () => {
    const taggedPorts = [];
    const untaggedPorts = [];

    Object.entries(assignedPorts).forEach(([serialNo, ports]) => {
      Object.entries(ports).forEach(([portNumber, state]) => {
        const portDetail = { serialNo, portNumber: parseInt(portNumber, 10) };
        if (state === "tagged") {
          taggedPorts.push(portDetail);
        } else if (state === "untagged") {
          untaggedPorts.push(portDetail);
        }
      });
    });

    const updatedData = {
      ...formData,
      taggedPorts,
      untaggedPorts,
    };

    setFormData(updatedData);
  };

  useEffect(() => {
    updateFormDataPorts();
  }, [assignedPorts]);

  const renderPorts = () => {
    if (portDetails.length === 0) {
      return <div>No ports available to display.</div>;
    }

    return portDetails.map((portConfig, index) => {
      const { model, serialNo, numberOfPorts, portMembers } = portConfig;

      const modelAssignedPorts = assignedPorts[serialNo] || {};

      return (
        <div key={serialNo} className="port-container">
          <h4>{model}</h4>
          <div className="router-port left mt-3">
            <div className="mxw-1260">
              <div className="polygon"></div>
              <div className="port-box">
                <ul className={`port port${numberOfPorts}`}>
                  {[...Array(numberOfPorts).keys()].map((i) => {
                    const portNumber = i + 1;
                    const portState = modelAssignedPorts[portNumber];
                    const portClass = portState
                      ? portState === "selected"
                        ? "selected"
                        : "assign"
                      : "unassign";
                    return (
                      <li
                        key={portNumber}
                        className={portClass}
                        onClick={() => handlePortClick(serialNo, portNumber)}
                      >
                        {portState === "trunk" ? `${portNumber}T` : portNumber}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const fetchMacAclStatus = async () => {
    try {
      const api = new allApi();
      const vlanId = data?.vlanId;
      const response = await api.getRequest(`api/vlan/acl/${vlanId}/${policy}`);
      if (response.ok) {
        const macAclData = response.data;
        setManualAclEntries(
          macAclData.filter((item) => item.macAclRule.createType === "manual")
        );
        setIsMacAclEnabled(macAclData.length > 0);
      }
    } catch (error) {
      console.error("Error fetching MAC ACL status:", error);
    }
  };

  const handlePolicyChange = (e) => {
    setPolicy(e.target.value);
  };

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const addManualEntry = () => {
    if (isManualMode) {
      const manualEntry = {
        deviceName: newAclEntry.deviceName,
        mac: newAclEntry.mac,
        allowFrom: allowFromThisDevice,
        allowTo: allowToThisDevice,
      };

      setManualAclEntries((prevEntries) => [...prevEntries, manualEntry]);
      setNewAclEntry({
        deviceName: "",
        mac: "",
        customConfig: "",
        allowFromThisDevice: false,
        allowToThisDevice: false,
      });
    } else {
      const customEntry = {
        customConfig: newAclEntry.customConfig,
      };
    }

    setShowModal(false);
  };

  const handleSaveAcl = () => {
    const payload = {
      vlanId: data?.vlanId,
      macAclList: {
        mode: policy,
        action: "0",
        macAclRule: {
          manual: manualAclEntries.map((entry) => ({
            deviceName: entry.deviceName,
            mac: entry.mac,
            allowFrom: entry.allowFrom,
            allowTo: entry.allowTo,
          })),
          custom: customAclEntries.map((entry) => ({
            customConfig: entry.customConfig,
          })),
        },
      },
    };
    setMacAclPayload(payload);
    onDataChange(payload);
  };

  return (
    <div>
      <h4>Wired Settings</h4>
      <p>Set where the VLAN will be applied.</p>

      <div className="accordion mt-4" id="accordionWidgets">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Port Members ({totalPortMembersCount})
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="port-member">
                <ul>
                  <li>
                    <i
                      className="fa fa-circle colorBlue"
                      aria-hidden="true"
                    ></i>
                    Assigned
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorGreen"
                      aria-hidden="true"
                    ></i>
                    Connected & Powered
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorLightGray"
                      aria-hidden="true"
                    ></i>
                    Disabled
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorBlack"
                      aria-hidden="true"
                    ></i>
                    Available
                  </li>
                  <li>
                    <i className="fa fa-circle colorRed" aria-hidden="true"></i>
                    Error
                  </li>
                </ul>
              </div>
              <div className="mt-4">
                <div className="mb-4 router-port">{renderPorts()}</div>
              </div>
              <div className="port-button">
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={handleTrunkPort}
                >
                  Trunk Port
                </button>
                <button
                  type="button"
                  className="btn btn-fill"
                  onClick={handleAccessPort}
                >
                  Access Port
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Advanced Settings
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="row tab-form mb-2">
                <div className="col-lg-6">
                  <div className="switch-box">
                    <strong>Voice Optimization</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        checked={voiceOptimization}
                        onChange={(e) => setVoiceOptimization(e.target.checked)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="switch-box">
                    <strong>Video Optimization (IGMP Snooping)</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        checked={videoOptimization}
                        onChange={(e) => setVideoOptimization(e.target.checked)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row tab-form mb-4">
                <div className="col-lg-6">
                  <label className="col-form-label">Traffic</label>
                  <select className="form-select">
                    <option>0 - Lowest Priority</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7 - Highest Priority</option>
                  </select>
                </div>
              </div>
              <div className="port-member">
                <ul>
                  <li>
                    <i
                      className="fa fa-circle colorBlue"
                      aria-hidden="true"
                    ></i>
                    Assigned
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorGreen"
                      aria-hidden="true"
                    ></i>
                    Connected & Powered
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorLightGray"
                      aria-hidden="true"
                    ></i>
                    Disabled
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorBlack"
                      aria-hidden="true"
                    ></i>
                    Available
                  </li>
                  <li>
                    <i className="fa fa-circle colorRed" aria-hidden="true"></i>
                    Error
                  </li>
                  <li>
                    <i className="fa fa-bolt colorGreen" aria-hidden="true"></i>
                    PoE
                  </li>
                  <li>
                    <strong className="colorBlack">L</strong>LAG Member
                  </li>
                  <li>
                    <strong className="colorBlack">S</strong>1G SFP Fiber Port
                  </li>
                  <li>
                    <strong className="colorBlack">S+</strong>10G SFP+Fiber Port
                  </li>
                  <li>
                    <i
                      className="fa fa-caret-up colorBlue"
                      aria-hidden="true"
                    ></i>
                    Uplink Port
                  </li>
                  <li>
                    <img src={Auth} alt="Authorized" />
                    Authorized
                  </li>
                  <li>
                    <img src={Unauth} alt="Unauthorized" />
                    Unauthorized
                  </li>
                  <li>
                    <strong className="colorRed">!</strong>Admin Down
                  </li>
                  <li>
                    <i className="fa fa-ban colorRed" aria-hidden="true"></i>
                    Blocked
                  </li>
                </ul>
              </div>
              <div className="port-button">
                <button type="button" className="text-btn primary-btn" disabled>
                  Auto
                </button>
                <button type="button" className="text-btn primary-btn" disabled>
                  Authorized
                </button>
                <button type="button" className="text-btn primary-btn" disabled>
                  Unauthorized
                </button>
              </div>

              <div className="row mt-4 tab-form">
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>MAC ACL</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        onClick={() => setShowPopup(true)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <div>
                    <label className="col-form-label">Policy</label>
                    <select
                      className="form-select"
                      value={policy}
                      onChange={handlePolicyChange}
                    >
                      <option value="0">Allow</option>
                      <option value="1">Deny</option>
                    </select>
                  </div>
                  {policy === "0" && manualAclEntries.length > 0 && (
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionWidgets"
                    >
                      <div className="accordion-body">
                        <div className="inc-card-table">
                          <table className="full-table m-head">
                            <thead>
                              <tr>
                                <th>
                                  <label className="checkbox">
                                    <input type="checkbox" />
                                    <span className="indicator"></span>
                                  </label>
                                </th>
                                <th>Device Name</th>
                                <th>IP Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              {manualAclEntries.map((entry, index) => (
                                <tr key={index}>
                                  <td>
                                    <label className="checkbox">
                                      <input type="checkbox" />
                                      <span className="indicator"></span>
                                    </label>
                                  </td>
                                  <td>{entry.deviceName}</td>
                                  <td>{entry.mac}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="port-button">
                    <button
                      type="button"
                      className="btn btn-fill"
                      onClick={() => setShowModal(true)}
                    >
                      Manual
                    </button>
                    <button type="button" className="btn btn-fill">
                      Remove device
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>Enable IP Filtering</strong>
                    <label className="ice-switch">
                      <input
                        type="checkbox"
                        onClick={() => setShowPopupIP(true)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <div>
                    <label className="col-form-label">Policy</label>
                    <select
                      className="form-select"
                      value={policy}
                      onChange={handlePolicyChange}
                    >
                      <option value="0">Allow</option>
                      <option value="1">Deny</option>
                    </select>
                  </div>
                  <div className="port-button">
                    <button
                      type="button"
                      className="btn btn-fill"
                      onClick={() => setShowModal(true)}
                    >
                      Manual
                    </button>
                    <button
                      type="button"
                      className="btn btn-fill"
                      onClick={setShowCustomPopup}
                    >
                      Custom
                    </button>
                    {/* <button type="button" className="btn btn-fill">
                      Add Device
                    </button> */}
                    <button type="button" className="btn btn-fill">
                      Remove device
                    </button>
                  </div>
                </div>
              </div>

              {showPopup && (
                <div className="customModal">
                  <div className="c-modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Enable MAC ACL</h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowPopup(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          When MAC ACL policy is enabled with at least one
                          device added in Allow/Policy list, the gateway router
                          MAC address also needs to be added to the allow list
                          to ensure proper traffic flow.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="text-btn primary-btn"
                          style={{
                            margin: "0 auto",
                          }}
                          onClick={() => setShowPopup(false)}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {showPopupIP && (
                <div className="customModal">
                  <div className="c-modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          Enable IP Filtering Policy
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          onClick={() => setShowPopup(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p
                          style={{
                            textAlign: "justify",
                          }}
                        >
                          Enable IP Filtering policy when at least one device is
                          added in Allow/Policy list.
                        </p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="text-btn primary-btn"
                          style={{
                            margin: "0 auto",
                          }}
                          onClick={() => setShowPopupIP(false)}
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`modal fade ${showModal ? "show d-block" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{
                  backgroundColor: showModal
                    ? "rgba(0, 0, 0, 0.5)"
                    : "transparent",
                }}
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {isManualMode
                          ? "MAC ACL Manual Entry"
                          : "MAC ACL Custom Entry"}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        {isManualMode ? (
                          <>
                            {/* Manual Entry Form */}
                            <div className="mb-3">
                              <label
                                htmlFor="deviceName"
                                className="form-label"
                              >
                                Device Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="deviceName"
                                placeholder="Enter Device Name"
                                value={newAclEntry.deviceName}
                                onChange={(e) =>
                                  setNewAclEntry({
                                    ...newAclEntry,
                                    deviceName: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="mb-3">
                              <label
                                htmlFor="macAddress"
                                className="form-label"
                              >
                                MAC Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="macAddress"
                                placeholder="Enter MAC Address (e.g., 00:1A:2B:3C:4D:5E)"
                                value={newAclEntry.mac}
                                onChange={(e) =>
                                  setNewAclEntry({
                                    ...newAclEntry,
                                    mac: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Custom Entry Form */}
                            <div className="mb-3">
                              <label
                                htmlFor="customConfig"
                                className="form-label"
                              >
                                Configuration Details
                              </label>
                              <textarea
                                className="form-control"
                                id="customConfig"
                                rows="4"
                                placeholder="Enter custom configuration details"
                                value={newAclEntry.customConfig}
                                onChange={(e) =>
                                  setNewAclEntry({
                                    ...newAclEntry,
                                    customConfig: e.target.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </>
                        )}

                        {/* Shared Checkboxes */}
                        <div className="mb-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="allowFromThisDevice"
                              checked={newAclEntry.allowFromThisDevice}
                              onChange={(e) =>
                                setNewAclEntry({
                                  ...newAclEntry,
                                  allowFromThisDevice: e.target.checked,
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowFromThisDevice"
                            >
                              Allow access from this device
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="allowToThisDevice"
                              checked={newAclEntry.allowToThisDevice}
                              onChange={(e) =>
                                setNewAclEntry({
                                  ...newAclEntry,
                                  allowToThisDevice: e.target.checked,
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allowToThisDevice"
                            >
                              Allow access to this device
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-fill"
                        onClick={addManualEntry}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline"
                        onClick={() => setShowModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showCustomPopup && (
            <div className="customModal">
              <div className="c-modal-dialog modal-dialog-centered small">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Custom Access Management</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowCustomPopup(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div>
                      <div className="mb-3">
                        <label className="col-form-label">From</label>
                        <select className="form-select">
                          <option>Manual</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Device Name</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">IP Address</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-check checkbox-label">
                        <label className="checkbox">
                          <input type="checkbox" id="rangedevice1" />
                          <span className="indicator"></span>
                        </label>
                        <label htmlFor="rangedevice1">
                          Add range of device
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <label className="col-form-label">To</label>
                        <select className="form-select">
                          <option>Manual</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Device Name</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">IP Address</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-check checkbox-label">
                        <label className="checkbox">
                          <input type="checkbox" id="rangedevice1" />
                          <span className="indicator"></span>
                        </label>
                        <label htmlFor="rangedevice1">
                          Add range of device
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-outline">
                      Cancel
                    </button>
                    <button type="submit" className="text-btn primary-btn">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>{" "}
      </div>
    </div>
  );
}

export default WiredSettings;
