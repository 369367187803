import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import ReactSlider from "react-slider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function LoadBalancing() {
  const [load, setLoad] = useState();
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [sliderValues, setSliderValues] = useState({
    maxClients: [0, 0, 0, 0], 
    clientRSSI: [0, 0, 0, 0],
    channelUtilization: [0, 0, 0, 0],
    stickyClients: [0, 0, 0, 0],
  });

  const [showSliders, setShowSliders] = useState({
    maxClients: false,
    clientRSSI: false,
    channelUtilization: false,
    stickyClients: false,
  });
  const [headingValues,setHeadingValues] = useState({
   loadBalancingStatus: 0,
   stickyClient: 0, 
  });
  const [showHeaders,setShowHeaders] = useState({
    loadBalancingStatus: false,
    stickyClient: false,
  })

  useEffect(()=>{
  getLoadBalancing();
  },[]);

  const handleCancelPopUp = () => {
    setShowSuccessPopup(false);
  }

  const getLoadBalancing = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/wireless/getLoadBalance?networkId=6001");
      if (response && response.response) {
        const data = response.response;
  
        // Set the load data
        setLoad(data);
        console.log("status",data.loadBalancingStatus);
        setHeadingValues({
          loadBalancingStatus: data.loadBalancingStatus,
          stickyClient: data.stickyClient,
        })
  
        // Map the API response to sliderValues
        setSliderValues({
          maxClients: [
            parseInt(data.maxClientPerRadio.wlan0, 10),
            parseInt(data.maxClientPerRadio.wlan1, 10),
            //parseInt(data.maxClientPerRadio.wlan2, 10),
            //parseInt(data.maxClientPerRadio.wlan2_6, 10),
          ],
          clientRSSI: [
            parseInt(data.rxRssi.wlan0, 10),
            parseInt(data.rxRssi.wlan1, 10),
            //parseInt(data.rxRssi.wlan2, 10),
            //parseInt(data.rxRssi.wlan2_6, 10),
          ],
          channelUtilization: [
            parseInt(data.channelUtilization.wlan0, 10),
            parseInt(data.channelUtilization.wlan1, 10),
            //parseInt(data.channelUtilization.wlan2, 10),
            //parseInt(data.channelUtilization.wlan2_6, 10),
          ],
          stickyClients: [
            parseInt(data.stickyClients.wlan0, 10),
            parseInt(data.stickyClients.wlan1, 10),
            //parseInt(data.stickyClients.wlan2, 10),
            //parseInt(data.stickyClients.wlan2_6, 10),
          ],
        });
  
        // Map the API status to showSliders
        setShowSliders({
          maxClients: !!data.maxClientPerRadio.status,
          clientRSSI: !!data.rxRssi.status,
          channelUtilization: !!data.channelUtilization.status,
          //stickyClients: !!data.stickyClients.status,
        });
        setShowHeaders({
          loadBalancingStatus: !!data.loadBalancingStatus,
          stickyClient: !!data.stickyClient
        })
      }
    } catch (error) {
      console.error("Error fetching load balance", error);
    }
  };
  

  const inputLoadBalance = async() =>{
    try{
     const api = new allApi();
     const payload = {
      loadBalancingStatus : showHeaders.loadBalancingStatus ? 1 : 0,
      stickyClient : showHeaders.stickyClient ? 1 : 0,
      channelUtilization:{
          status :showSliders.channelUtilization ? 1 : 0,
          wlan0: sliderValues.channelUtilization[0].toString(),
          wlan1: sliderValues.channelUtilization[1].toString(),
          //wlan2: sliderValues.channelUtilization[2].toString(),
          //wlan2_6: sliderValues.channelUtilization[3].toString()
  
      },
      maxClientPerRadio:{
           status : showSliders.maxClients ? 1 : 0,
          wlan0: sliderValues.maxClients[0].toString(),
          wlan1: sliderValues.maxClients[1].toString(),
          //wlan2: sliderValues.maxClients[2].toString(),
          //wlan2_6: sliderValues.maxClients[3].toString()
      },
      rxRssi:{
           status :showSliders.clientRSSI ? 1 : 0,
          wlan0: sliderValues.clientRSSI[0].toString(),
          wlan1: sliderValues.clientRSSI[1].toString(),
          //wlan2: sliderValues.clientRSSI[2].toString(),
          //wlan2_6: sliderValues.clientRSSI[3].toString()
      },
      stickyClients:{
          status :showSliders.stickyClients ? 1: 0,
          wlan0: sliderValues.stickyClients[0].toString(),
          wlan1: sliderValues.stickyClients[1].toString(),
          //wlan2: sliderValues.stickyClients[2].toString(),
          //wlan2_6: sliderValues.stickyClients[3].toString()
      }
     };
     const response = await api.postRequest("api/wireless/loadBalance?networkId=6001","",payload);
     if(response){
      setShowSuccessPopup(true);
      console.log("Successfully set Load Balancing",response);
     }
    }catch(error){
     console.error("Error in input load",error);
    }
  };

  // Handle slider value change
  const handleSliderChange = (field, index, value) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [field]: prevValues[field].map((val, i) => (i === index ? value : val)),
    }));
  };

  const toggleSliders = (field) => {
    setShowSliders((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  const toggleHeaders = (field) => {
    setShowHeaders((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const renderSliders = (field) => {
    const sliderRanges = {
      maxClients: { min: 5, max: 200 },
      clientRSSI: { min: 1, max: 50 },
      channelUtilization: { min: 50, max: 90 },
      stickyClients: [
        { min: -90, max: -85 }, // wlan0 range
        { min: -90, max: -85 }, // wlan1 range
      ],
    };
  
    return (
      <div style={{ margin: "40px 0" }}>
        {sliderValues[field].map((value, index) => {
          // Determine the slider range dynamically
          const range =
            field === "stickyClients" ? sliderRanges.stickyClients[index] : sliderRanges[field];
  
          return (
            <div key={index} className="load-slider">
              <span>
                {index === 0
                  ? "2.4 GHz"
                  : index === 1
                  ? "5 GHz"
                  : index === 2
                  ? "5 GHz High"
                  : "6 GHz"}
              </span>
              <div className="slider-value">
                <span>{range.min}</span>
                <ReactSlider
                  min={range.min}
                  max={range.max}
                  value={value}
                  onChange={(val) => handleSliderChange(field, index, val)}
                  className="custom-slider"
                  thumbClassName="custom-thumb"
                  trackClassName="custom-track"
                  renderThumb={(props, state) => (
                    <div {...props} className="custom-thumb">
                      {state.valueNow}
                    </div>
                  )}
                  renderTrack={(props, state) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                    />
                  )}
                />
                <span>{range.max}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  
  
  

  return (
    <div className="w-scroll">
      <p>
        Load balancing facilitates equal distribution of clients across your available access points.
        When an AP reaches its capacity based on number of clients or channel utilization, load balancing prevents new clients from connecting.
        It can prevent far away clients with low RSSI from connecting, and dissociate connected clients so they can connect to a neighboring AP.
      </p>

      <div className="tab-form">
        {/* Load Balancing */}
        <div className="row mb-4">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong>Load Balancing</strong>
              <label className="ice-switch">
                <input type="checkbox" 
                checked={showHeaders.loadBalancingStatus}
                onChange={()=>toggleHeaders("loadBalancingStatus")}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
         {showHeaders.loadBalancingStatus && (
        <div style={{ marginBottom: "20px" }}>
          <div className="load-list">
            <div className="load-type">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={showSliders.maxClients}
                  onChange={() => toggleSliders("maxClients")}
                />
                <span className="indicator"></span>
                <p>Maximum number of clients per radio</p>
              </label>
            </div>
            {showSliders.maxClients && renderSliders("maxClients")}
          </div>

          {/* Balance on client Rx RSSI */}
          <div className="load-list">
            <div className="load-type">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={showSliders.clientRSSI}
                  onChange={() => toggleSliders("clientRSSI")}
                />
                <span className="indicator"></span>
                <p>Balance on client Rx RSSI</p>
              </label>
            </div>
            {showSliders.clientRSSI && renderSliders("clientRSSI")}
          </div>

          {/* Balance on channel utilization */}
          <div className="load-list">
            <div className="load-type">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={showSliders.channelUtilization}
                  onChange={() => toggleSliders("channelUtilization")}
                />
                <span className="indicator"></span>
                <p>Balance on channel utilization</p>
              </label>
            </div>
            {showSliders.channelUtilization && renderSliders("channelUtilization")}
          </div>
        </div>
         )}
        {/* Disassociate sticky clients */}
        <div className="row mb-4 mt-4">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong>Disassociate sticky clients</strong>
              <label className="ice-switch">
                <input
                  type="checkbox"
                  checked={showHeaders.stickyClient}
                  onChange={() => toggleHeaders("stickyClient")}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
        {showHeaders.stickyClient && (
          <p>Disassociate clients by force when RSSI gets too low so they can connect to a stronger AP.</p>
        )}
        {showHeaders.stickyClient && renderSliders("stickyClients")}
      </div>
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">Cancel</button>
        <button type="submit" className="text-btn primary-btn" onClick={()=>inputLoadBalance()}>Save</button>
      </div>
      <Modal
        show={showSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Load Balance changed successfully</p>
        <Button onClick={handleCancelPopUp}>
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default LoadBalancing;
