import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import { all } from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function VlanManagement() {
  const [info, setInfo] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showUntaggedPopup, setShowUntaggedPopup] = useState(false);
  const [customUntaggedVlans, setCustomUntaggedVlans] = useState([]);
  const [isUntaggedEditing, setIsUntaggedEditing] = useState(false);
  const [list, setList] = useState([]);
  const [untaggedList, setUntaggedList] = useState([]);
  const [customVlans, setCustomVlans] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Determines if editing
  const [editVlan, setEditVlan] = useState({ vlan_name: "", vlan_id: "" });
  const [editUntaggedVlan, setEditUntaggedVlan] = useState({ vlan_name: "", vlan_id: "" });
  const [vlanFields, setVlanFields] = useState({
    untaggedVlanStatus: 0,
    untaggedVlanId: "",
    managementVlanId: ""
  });


  useEffect(() => {
    getVlanManagement();
    getManagementList();
    getUntaggedList();
  }, []);

  const getVlanManagement = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest(
        "api/wireless/getVlanManagement?networkId=6001"
      );

      if (response && response.response) {
        const data = response.response;

        // Extract details from the response
        const managementVlan = data.vlanDetails.find(
          (vlan) => vlan.managementVlanId
        );
        const untaggedVlan = data.vlanDetails.find(
          (vlan) => vlan.unTaggedVlanId
        );

        // Map the response data to component state
        setInfo(data);
        setVlanFields({
          managementVlanId: managementVlan?.managementVlanId || "",
          untaggedVlanStatus: data.unTaggedVlanStatus || 0,
          untaggedVlanId: untaggedVlan?.unTaggedVlanId || "",
        });
      }
    } catch (error) {
      console.error("Error in fetching VLAN info", error);
    }
  };



  const getManagementList = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/wireless/getManagementVlanId");

      if (response && response.response) {
        setList(response.response);
      } else {
        console.error("Failed to fetch VLAN list:", response?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching VLAN list:", error);
    }
  };

  const getUntaggedList = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/wireless/getUntaggedVlanId");

      if (response && response.response) {
        // Directly use the data from the response
        setUntaggedList(response.response);
      } else {
        console.error("Failed to fetch VLAN list:", response?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching VLAN list:", error);
    }
  };


  const inputVlan = async () => {
    try {
      const api = new allApi();
      const payload = {
        managementVlanId: parseInt(vlanFields.managementVlanId),
        untaggedVlanStatus: vlanFields.untaggedVlanStatus,
        untaggedVlanId: parseInt(vlanFields.untaggedVlanId)

      }
      const response = await api.postRequest("api/wireless/vlanManagement?networkId=6001", "", payload);
      if (response) {
        console.log("Successfully sent vlan info", response);
        setShowSuccessPopup(true);
      }
    } catch (error) {
      console.error("Error in payload for vlan", error);
    }
  };
  const handleCancelPopUp = () => {
    setShowSuccessPopup(false);
  }

  const handleVlanChange = (field, value) => {
    setVlanFields({
      ...vlanFields,
      [field]: field === "untaggedVlanStatus" ? parseInt(value) : value
    });
  };

  const hasCustomVlan = () => {
    return (
      list.some((vlan) => vlan.vlan_name === "Custom Management Vlan") ||
      customVlans.length > 0
    );
  };
  const hasCustomUntaggedVlan = () => {
    return (
      untaggedList.some((vlan) => vlan.vlan_name === "Custom Management Vlan") ||
      customUntaggedVlans.length > 0
    );
  };



  const handleSaveCustomVlan = () => {
    const customVlanIndex = list.findIndex(
      (vlan) => vlan.vlan_name === "Custom Management Vlan"
    );

    if (isEditing) {
      setCustomVlans((prev) =>
        prev.map((vlan) =>
          vlan.vlan_id === editVlan.original_vlan_id
            ? { vlan_name: editVlan.vlan_name, vlan_id: editVlan.vlan_id }
            : vlan
        )
      );
    } else if (!hasCustomVlan()) {
      setCustomVlans([{ vlan_name: editVlan.vlan_name, vlan_id: editVlan.vlan_id }]);
    } else if (customVlanIndex !== -1) {
      const updatedList = [...list];
      updatedList[customVlanIndex] = {
        vlan_name: editVlan.vlan_name,
        vlan_id: editVlan.vlan_id,
      };
      setList(updatedList);
    }

    setShowPopup(false);
  };



  const handleSaveCustomUntaggedVlan = () => {
    const customUntaggedVlanIndex = untaggedList.findIndex(
      (vlan) => vlan.vlan_name === "Custom Management Vlan"
    );

    if (isUntaggedEditing) {
      // Update existing custom untagged VLAN in `customUntaggedVlans`
      setCustomUntaggedVlans((prev) =>
        prev.map((vlan) =>
          vlan.vlan_id === editUntaggedVlan.original_vlan_id
            ? { vlan_name: editUntaggedVlan.vlan_name, vlan_id: editUntaggedVlan.vlan_id }
            : vlan
        )
      );
    } else if (!hasCustomUntaggedVlan()) {
      // Add new custom untagged VLAN if none exists
      setCustomUntaggedVlans([
        { vlan_name: editUntaggedVlan.vlan_name, vlan_id: editUntaggedVlan.vlan_id },
      ]);
    } else if (customUntaggedVlanIndex !== -1) {
      // Update the existing custom VLAN in the `untaggedList`
      const updatedUntaggedList = [...untaggedList];
      updatedUntaggedList[customUntaggedVlanIndex] = {
        vlan_name: editUntaggedVlan.vlan_name,
        vlan_id: editUntaggedVlan.vlan_id,
      };
      setUntaggedList(updatedUntaggedList);
    }

    setShowUntaggedPopup(false);
    setIsUntaggedEditing(true); // Enable editing mode for subsequent actions
  };



  const openPopup = (vlan = { vlan_name: "Custom Management Vlan", vlan_id: "" }) => {
    setEditVlan({ ...vlan, original_vlan_id: vlan.vlan_id }); // Ensure original ID is tracked for editing
    setShowPopup(true);
    setIsEditing(!!vlan.vlan_id); // Determine if editing based on presence of VLAN ID
  };



  const openUntaggedPopup = (vlan = { vlan_name: "Custom Management Vlan", vlan_id: "" }) => {
    setEditUntaggedVlan({ ...vlan, original_vlan_id: vlan.vlan_id });
    setShowUntaggedPopup(true);
    setIsUntaggedEditing(!!vlan.vlan_id); // Editing mode if VLAN ID exists
  };


  const combinedVlans = [...list, ...customVlans];
  const combinedUntaggedVlans = [...untaggedList, ...customUntaggedVlans];
  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-6">
          <label className="col-form-label">Management VLAN</label>
          <select
            className="form-select"
            value={vlanFields.managementVlanId}
            onChange={(e) =>
              handleVlanChange("managementVlanId", parseInt(e.target.value))
            }
          >
            {/* <option>Management VLAN</option> */}
            {combinedVlans.map((vlan) => (
              <option key={vlan.vlan_id} value={vlan.vlan_id}>
                {vlan.vlan_name} ({vlan.vlan_id})
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="edit-custom-link">
        <a
          href="#"
          onClick={() => openPopup(customVlans[0] || { vlan_name: "Custom Management Vlan", vlan_id: "" })}
        >
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          {hasCustomVlan() ? "Edit Custom VLAN" : "Add Custom VLAN"}
        </a>
      </div>



      <div className="row mb-3 mt-4">
        <div className="col-lg-6">
          <label className="col-form-label label-check">
            Untagged VLAN
            <label className="checkbox">
              <input
                type="checkbox"
                checked={vlanFields.untaggedVlanStatus === 1}
                value={vlanFields.untaggedVlanStatus}
                onChange={(e) =>
                  handleVlanChange(
                    "untaggedVlanStatus",
                    e.target.checked ? 1 : 0
                  )
                }
              />
              <span className="indicator"></span>
            </label>
          </label>
          <select
            className="form-select"
            value={vlanFields.untaggedVlanId}
            onChange={(e) =>
              handleVlanChange("untaggedVlanId", parseInt(e.target.value))
            }
          >
            {/* <option>Untagged VLAN</option> */}
            {combinedUntaggedVlans.map((vlan) => (
              <option key={vlan.vlan_id} value={vlan.vlan_id}>
                {vlan.vlan_name} ({vlan.vlan_id})
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="edit-custom-link">
        <a
          href="#"
          onClick={() => openUntaggedPopup(customUntaggedVlans[0] || { vlan_name: "Custom Management Vlan", vlan_id: "" })}
        >
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
          {hasCustomUntaggedVlan() ? "Edit Custom VLAN" : "Add Custom VLAN"}
        </a>
      </div>


      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">Cancel</button>
        <button type="submit" className="text-btn primary-btn" onClick={() => inputVlan()}>Save</button>
      </div>

      {showPopup && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered small">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isEditing ? "Edit VLAN" : "Add VLAN"}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowPopup(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">VLAN Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={"Custom Management Vlan"}
                    readOnly // Fixed VLAN name
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">VLAN ID</label>
                  <input
                    type="number"
                    className="form-control"
                    value={editVlan.vlan_id}
                    onChange={(e) =>
                      setEditVlan({
                        ...editVlan,
                        vlan_id: parseInt(e.target.value) || "", // Parse input as a number
                      })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => setShowPopup(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={handleSaveCustomVlan}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {showUntaggedPopup && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered small">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isUntaggedEditing ? "Edit Untagged VLAN" : "Add Untagged VLAN"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowUntaggedPopup(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">VLAN Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={"Custom Management Vlan"}
                    readOnly // Fixed VLAN name
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">VLAN ID</label>
                  <input
                    type="number"
                    className="form-control"
                    value={editUntaggedVlan.vlan_id}
                    onChange={(e) =>
                      setEditUntaggedVlan({
                        ...editUntaggedVlan,
                        vlan_id: parseInt(e.target.value) || "",
                      })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={() => setShowUntaggedPopup(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-btn primary-btn"
                  onClick={handleSaveCustomUntaggedVlan}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Management VLAN Settings has been saved successfully.</p>
        <Button onClick={handleCancelPopUp}>
          OK
        </Button>
      </Modal>

    </div>
  );
}

export default VlanManagement;
