import React from "react";

function ReportReview({ reportData, widgets }) {
  const { reportName, description, dataType = [] } = reportData || {};

  // Merge widgets into dataType if widgets prop is provided
  const selectedWidgets = widgets?.length
    ? widgets.map((widget) => ({
        widget_name: widget.widget_name,
        widget_desc: widget.widget_desc,
      }))
    : dataType;

  return (
    <div>
      <h4>Review</h4>
      <p>
        Review the report profile, filter, and widgets, and generate a preview
        of how the report would look when generated.
      </p>
      <div className="accordion" id="accordionReview">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Report Details
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionReview"
          >
            <div className="accordion-body">
              <div className="d-flex">
                <div className="p-report">
                  <label className="form-label">Report Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={reportName || ""}
                    readOnly
                  />
                </div>
                <div className="r-profile">
                  <label className="form-label">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    value={description || ""}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Accordion for Widgets */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Widgets
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionReview"
          >
            <div className="accordion-body">
              <div className="inc-card-table">
                <table className="m-head">
                  <thead>
                    <tr>
                      <th>Widget Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedWidgets.length > 0 ? (
                      selectedWidgets.map((widget, index) => (
                        <tr key={index}>
                          <td>{widget.widgetName}</td>
                          <td>{widget.widgetDesc}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2">No widgets selected</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportReview;
