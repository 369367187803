import React, { useState, useEffect } from "react";

function DeviceSettings() {
  const [mode, setMode] = useState("Master");
  const [linkType, setLinkType] = useState("P2P");
  const [noOfSlaves, setNoOfSlaves] = useState(1);

  const handleModeChange = (e) => {
    setMode(e.target.value);
    setLinkType("P2P");
  };

  const handleLinkTypeChange = (e) => {
    setLinkType(e.target.value);
  };

  const handleNoOfSlavesChange = (e) => {
    setNoOfSlaves(parseInt(e.target.value, 10));
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Device Settings</h3>
          </div>
          <div className="inc-card-body">
            <div className="sideTab-container">
              <div className="inc-side-tab snmp-config-link">
                <ul className="nav-side w-scroll" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <button
                      className="nav-link active"
                      id="pills-general-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-general"
                      type="button"
                      role="tab"
                      aria-controls="pills-general"
                      aria-selected="true"
                    >
                      <div>
                        General
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Device Identifiers</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-backhaul-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-backhaul"
                      type="button"
                      role="tab"
                      aria-controls="pills-backhaul"
                      aria-selected="true"
                    >
                      <div>
                        Backhaul Radio
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Internet Exchange Point</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-ubr-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-ubr"
                      type="button"
                      role="tab"
                      aria-controls="pills-ubr"
                      aria-selected="true"
                    >
                      <div>
                        UBR Link Settings
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Unlicensed Band Radio</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-atpc-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-atpc"
                      type="button"
                      role="tab"
                      aria-controls="pills-atpc"
                      aria-selected="true"
                    >
                      <div>
                        ATPC<i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Automatic Transmit Power Control</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-device-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-device"
                      type="button"
                      role="tab"
                      aria-controls="pills-device"
                      aria-selected="true"
                    >
                      <div>
                        Device Password
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Protects Your Device</span>
                      </div>
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      id="pills-events-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-events"
                      type="button"
                      role="tab"
                      aria-controls="pills-events"
                      aria-selected="true"
                    >
                      <div>
                        Events
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                        <span>Entuity Incidents</span>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="inc-side-tabcontainer">
                <div
                  className="tab-form snmp-config-tab tab-content w-scroll"
                  id="pills-tabContent"
                >
                  {/* ==================FIRST TAB=========== */}
                  <div
                    className="tab-pane fade show active"
                    id="pills-general"
                    role="tabpanel"
                    aria-labelledby="pills-general-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Device Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="StarLoom"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          Model
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="INC-SNMP457"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Device DateTime
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Tue, 2 Jul 2024, 10:54:36"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          TimeZone
                        </label>
                        <select className="form-select">
                          <option>Dummy Text</option>
                        </select>
                      </div>
                    </div>
                    <div class="wifi-footer">
                      <button type="button" class="btn btn-outline">
                        Cancel
                      </button>
                      <button type="submit" class="text-btn primary-btn">
                        Save
                      </button>
                    </div>
                  </div>
                  {/* ===FIRST TAB END=== */}

                  {/* ==================SECOND TAB=========== */}
                  <div
                    class="tab-pane fade"
                    id="pills-backhaul"
                    role="tabpanel"
                    aria-labelledby="pills-backhaul-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Channel Bandwidth
                        </label>
                        <select className="form-select">
                          <option>Auto</option>
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          DFS
                        </label>
                        <select className="form-select">
                          <option>Disable</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Channel Selection
                        </label>
                        <select className="form-select">
                          <option>abc</option>
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="subnetmask" className="col-form-label">
                          TX Power
                        </label>
                        <select className="form-select">
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                          <option>13</option>
                          <option>14</option>
                          <option>15</option>
                          <option>16</option>
                          <option>17</option>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                          <option>21</option>
                          <option>22</option>
                          <option>23</option>
                          <option>24</option>
                          <option>25</option>
                          <option>26</option>
                          <option>27</option>
                          <option>28</option>
                        </select>
                      </div>
                    </div>
                    <div class="wifi-footer">
                      <button type="button" class="btn btn-outline">
                        Cancel
                      </button>
                      <button type="submit" class="text-btn primary-btn">
                        Save
                      </button>
                    </div>
                  </div>
                  {/* ===SECOND TAB END=== */}

                  {/* ==================THIRD TAB=========== */}
                  <div
                    className="tab-pane fade"
                    id="pills-ubr"
                    role="tabpanel"
                    aria-labelledby="pills-ubr-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label htmlFor="mode" className="col-form-label">
                          Mode
                        </label>
                        <select
                          className="form-select"
                          id="mode"
                          onChange={handleModeChange}
                          value={mode}
                        >
                          <option value="Master">Master</option>
                          <option value="Slave">Slave</option>
                        </select>
                      </div> 
                      {mode === "Master" && (                     
                       <div className="col-lg-4">
                       <label htmlFor="linkType" className="col-form-label">
                         Link Type
                       </label>
                       <select
                         className="form-select"
                         id="linkType"
                         onChange={handleLinkTypeChange}
                         value={linkType}
                       >
                         <option value="P2P">P2P</option>
                         <option value="P2MP">P2MP</option>
                       </select>
                     </div>
                      )}
                    </div>

                    {mode === "Master" && (
                      <>
                        {linkType === "P2MP" && (
                          <>
                            <div className="row mb-3">
                              <div className="col-lg-4">
                                <label
                                  htmlFor="noOfSlaves"
                                  className="col-form-label"
                                >
                                  No. Of Slaves
                                </label>
                                <select
                                  className="form-select"
                                  id="noOfSlaves"
                                  onChange={handleNoOfSlavesChange}
                                  value={noOfSlaves}
                                >
                                  {[...Array(2).keys()].map((_, index) => (
                                    <option key={index} value={index + 1}>
                                      {index + 1}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {[...Array(noOfSlaves)].map((_, index) => (
                                <div className="col-lg-4" key={index}>
                                  <label
                                    htmlFor={`slaveUid${index + 1}`}
                                    className="col-form-label"
                                  >
                                    Slave UID {index + 1}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={`slaveUid${index + 1}`}
                                    placeholder={`Enter Slave UID ${index + 1}`}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="row mb-3">
                              <div className="col-lg-4">
                                <label
                                  htmlFor="linkSecurity"
                                  className="col-form-label"
                                >
                                  Link Security
                                </label>
                                <select
                                  className="form-select"
                                  id="linkSecurity"
                                >
                                  <option value="WPA2-personal">
                                    WPA2-personal
                                  </option>
                                </select>
                              </div>
                              <div className="col-lg-4">
                                <label
                                  htmlFor="linkPassword"
                                  className="col-form-label"
                                >
                                  Link Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="linkPassword"
                                  placeholder="Password"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {linkType === "P2P" && (
                          <>
                            <div className="row mb-3">
                              <div className="col-lg-4">
                                <label
                                  htmlFor="linkSecurity"
                                  className="col-form-label"
                                >
                                  Link Security
                                </label>
                                <select
                                  className="form-select"
                                  id="linkSecurity"
                                >
                                  <option value="WPA2-personal">
                                    WPA2-personal
                                  </option>
                                </select>
                              </div>
                              <div className="col-lg-4">
                                <label
                                  htmlFor="linkPassword"
                                  className="col-form-label"
                                >
                                  Link Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  id="linkPassword"
                                  placeholder="Password"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {mode === "Slave" && (
                      <>
                        <div className="row mb-3">
                          <div className="col-lg-4">
                            <label
                              htmlFor="masterUuid"
                              className="col-form-label"
                            >
                              Master UUID
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="masterUuid"
                              placeholder="Enter Master UUID"
                            />
                          </div>
                          <div className="col-lg-4">
                            <label
                              htmlFor="linkSecurity"
                              className="col-form-label"
                            >
                              Link Security
                            </label>
                            <select className="form-select" id="linkSecurity">
                              <option value="WPA2-personal">
                                WPA2-personal
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-3">
                        <div className="col-lg-4">
                            <label
                              htmlFor="linkPassword"
                              className="col-form-label"
                            >
                              Link Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="linkPassword"
                              placeholder="Password"
                            />
                          </div>
                          </div>
                      </>
                    )}

                    <div className="wifi-footer">
                      <button type="button" className="btn btn-outline">
                        Cancel
                      </button>
                      <button type="submit" className="text-btn primary-btn">
                        Save
                      </button>
                    </div>  
                  </div>

                  {/* ===THIRD TAB END=== */}

                  {/* ==================FOURTH TAB=========== */}
                  <div
                    class="tab-pane fade"
                    id="pills-atpc"
                    role="tabpanel"
                    aria-labelledby="pills-atpc-tab"
                  >
                    <div className="row mb-4">
                      <div class="col-lg-4">
                        <div class="switch-box">
                          <strong>ATPC Enable</strong>
                          <label class="ice-switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Max tx Power
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="45"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          Min tx Power
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="12"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Max RSSI
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="15"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Min RSSI
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="08"
                        />
                      </div>
                    </div>
                    <div class="wifi-footer">
                      <button type="button" class="btn btn-outline">
                        Cancel
                      </button>
                      <button type="submit" class="text-btn primary-btn">
                        Save
                      </button>
                    </div>
                  </div>
                  {/* ===FOURTH TAB END=== */}

                  {/* ==================FIFTH TAB=========== */}
                  <div
                    class="tab-pane fade"
                    id="pills-device"
                    role="tabpanel"
                    aria-labelledby="pills-device-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <label for="ipaddress" className="col-form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                        />
                      </div>
                      <div className="col-lg-4">
                        <label for="subnetmask" className="col-form-label">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                    <div class="wifi-footer">
                      <button type="button" class="btn btn-outline">
                        Cancel
                      </button>
                      <button type="submit" class="text-btn primary-btn">
                        Save
                      </button>
                    </div>
                  </div>
                  {/* ===FIFTH TAB END=== */}

                  {/* ==================SIXTH TAB=========== */}
                  <div
                    class="tab-pane fade event-tab"
                    id="pills-events"
                    role="tabpanel"
                    aria-labelledby="pills-events-tab"
                  >
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>System Events</strong>
                        <div className="row mb-2 mt-3">
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>CPU Overload</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>CPU Overload Clear</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Memory Overload</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Memory Overload Clear</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>GPS Not Locked</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>GPS Locked</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>System Reboot Trap</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Temperature Threshold Reached</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>
                                Temperature Threshold Reached Clear
                              </strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Speed Changed</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Duplex Changed</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>Link Events</strong>
                        <div className="row mb-2 mt-3">
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Error Overload</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Error Overload Clear</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Wireless Error Overload</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Wireless Error Overload Clear</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Link Up</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Link Down</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Radio Up</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Radio Down</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Up</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Ethernet Down</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Link Password Change</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Chain Disconnect</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Chain Disconnect Clear</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>Firmware Events</strong>
                        <div className="row mb-2 mt-3">
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Firmware Upgrade Success</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Firmware Upgrade Fail</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <strong>Threshold</strong>
                        <div className="row mb-2 mt-3">
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>CPU Threshold</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="switch-box">
                              <strong>Memory Threshold</strong>
                              <label class="ice-switch">
                                <input type="checkbox" />
                                <span class="slider"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="wifi-footer">
                      <button type="button" class="btn btn-outline">
                        Cancel
                      </button>
                      <button type="submit" class="text-btn primary-btn">
                        Save
                      </button>
                    </div>
                  </div>
                  {/* ===SIXTH TAB END=== */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceSettings;
