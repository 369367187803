import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import allApi from "../../../../api/allApi";
import Loader from "../../common/Loader";
import Modal from "react-bootstrap/Modal";
import errorIcon from "../../../../assets/images/error-2.png";

function Reports() {
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState({
    name: "",
    description: "",
    widgets: "",
    status: "",
    createdAt: "",
  });
  const [sortOrder, setSortOrder] = useState({ column: "", order: "asc" });
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;
  const totalPages = Math.ceil(filteredReports.length / pageSize);
  const [modalData, setModalData] = useState(null);
  const [modalData1, setModalData1] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [reportToSend, setReportToSend] = useState(null);
  const [reportName, setReportName] = useState("");
  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest("api/report/list");
      if (response && Array.isArray(response)) {
        const sortedReports = response.sort(
          (a, b) => b.createdAt - a.createdAt
        );
        setReports(sortedReports);
        setFilteredReports(sortedReports);
        setSelectedReports([]);
        setSelectAll(false);
      } else {
        setReports([]);
        setFilteredReports([]);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDeleteModal = (report) => {
    if (report) {
      setReportToDelete(report.reportId); // For single report deletion
      setReportName(report.reportName);
    } else {
      setReportToDelete(null); // For multiple report deletion
    }
    setIsModalOpen1(true);
  };

  const handleCloseDeleteModal = (report) => {
    setReportToDelete(null);
    setReportName("");
    // setModalData1({ id });
    setIsModalOpen1(false);
  };

  const handleDeleteReport = async () => {
    if (!reportToDelete && selectedReports.length === 0) return;

    setLoading(true);
    try {
      const api = new allApi();
      const payload = reportToDelete
        ? { reportId: [reportToDelete] }
        : { reportId: selectedReports };
      const response = await api.deleteRequest(
        "api/report/deleteReports",
        payload
      );

      if (response) {
        fetchReports();
        handleCloseDeleteModal();
      }
    } catch (err) {
      console.error(`Error during deletion: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (reportId, reportName) => {
    try {
      const api = new allApi();
      const response = await api.getDownloadRequest(
        `api/report/download/${reportId}`,
        {
          responseType: "blob",
        }
      );

      if (!response) {
        throw new Error("No response received from the server.");
      }
      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${reportName}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setError("Failed to download the report.");
      console.error("Download error:", error);
    }
  };

  const handleViewReport = async (reportId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getDownloadRequest(
        `api/report/view/${reportId}`,
        {
          responseType: "blob",
        }
      );

      if (!response) {
        throw new Error("Failed to fetch the report.");
      }

      if (response instanceof Blob) {
        const url = window.URL.createObjectURL(response);
        setModalData(url);
        setIsModalOpen(true);
      } else {
        throw new Error("Received non-PDF response.");
      }
    } catch (error) {
      console.error("View Report Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = (reportId, emailRecipients) => {
    if (!emailRecipients || !Array.isArray(emailRecipients)) {
      console.error("Invalid report data: ", emailRecipients);
      setEmail("No recipients available.");
    } else {
      const recipients = emailRecipients.join(", ");
      setEmail(recipients);
    }
    setReportToSend(reportId);
    setIsConfirmationModalOpen(true);
  };


  const handleConfirmSendEmail = async () => {
    if (!reportToSend) return;

    setLoading(true);
    try {
      const api = new allApi();
      const payload = {
        reportId: reportToSend,
      };
      const response = await api.postRequest(
        "api/report/send-report",
        "",
        payload
      );
      if (response) {
        setIsSuccessMessageVisible(true); // Show success message after email is sent
        setIsConfirmationModalOpen(false); // Close confirmation modal
      }
    } catch (error) {
      console.error("Error sending report email:", error);
      alert("Error sending report email.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    const updatedSearch = { ...searchTerm, [name]: value };
    setSearchTerm(updatedSearch);

    const allFieldsEmpty = Object.values(updatedSearch).every(
      (term) => term.trim() === ""
    );
    if (allFieldsEmpty) {
      setFilteredReports(reports);
      return;
    }

    const filtered = reports.filter((report) => {
      const matches = {
        name: report.reportName
          ?.toLowerCase()
          .includes(updatedSearch.name.toLowerCase()),
        description: report.description
          ?.toLowerCase()
          .includes(updatedSearch.description.toLowerCase()),
        widgets: report.widgetList
          ?.join(", ")
          .toLowerCase()
          .includes(updatedSearch.widgets.toLowerCase()),
        status: (report.reportStatus === 1 ? "success" : "pending")
          .toLowerCase()
          .includes(updatedSearch.status.toLowerCase()),
        createdAt: new Date(report.createdAt * 1000)
          .toLocaleDateString()
          .includes(updatedSearch.createdAt),
      };

      return Object.values(matches).every((match) => match);
    });

    setFilteredReports(filtered);
  };

  const handleSortChange = (column) => {
    const newOrder =
      sortOrder.column === column && sortOrder.order === "asc" ? "desc" : "asc";
    const sorted = [...filteredReports].sort((a, b) => {
      let valueA, valueB;

      if (column === "widgets") {
        valueA = a.widgetList?.join(", ").toLowerCase() || "";
        valueB = b.widgetList?.join(", ").toLowerCase() || "";
      } else if (column === "createdAt") {
        valueA = new Date(a.createdAt * 1000);
        valueB = new Date(b.createdAt * 1000);
      } else if (column === "status") {
        valueA = a.reportStatus === 1 ? "success" : "pending";
        valueB = b.reportStatus === 1 ? "success" : "pending";
      } else {
        valueA = a[column]?.toString().toLowerCase() || "";
        valueB = b[column]?.toString().toLowerCase() || "";
      }

      if (valueA < valueB) return newOrder === "asc" ? -1 : 1;
      if (valueA > valueB) return newOrder === "asc" ? 1 : -1;
      return 0;
    });

    setFilteredReports(sorted);
    setSortOrder({ column, order: newOrder });
  };

  const handleSelectReport = (id) => {
    const updatedSelection = selectedReports.includes(id)
      ? selectedReports.filter((reportId) => reportId !== id)
      : [...selectedReports, id];
    setSelectedReports(updatedSelection);
    setSelectAll(updatedSelection.length === filteredReports.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedReports([]);
    } else {
      const allIds = filteredReports.map((report) => report.reportId);
      setSelectedReports(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const getPageNumbers = () => {
    return [...Array(totalPages).keys()];
  };

  const paginatedReports = filteredReports.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  return (
    <div>
      <div className="inc-card ch-100">
        <div className="inc-card-title">
          <h3>Reports</h3>
          <div className="inc-card-button">
            <button
              className="text-btn primary-btn"
              role="button"
              onClick={() => navigate("/reports-listing")}
            >
              <i className="fa fa-plus" aria-hidden="true"></i> Create Report
            </button>
            <button className="icon-btn" title="Refresh" onClick={fetchReports}>
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
            <button
              className="icon-btn"
              title="Delete"
              disabled={selectedReports.length <= 1}
              onClick={() => handleOpenDeleteModal()}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div className="inc-card-body">
          <div className="tab-pagination">
            <div className="inc-card-table">
              <table className="full-table m-head action150">
                <thead>
                  <tr>
                    <th>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                        <span className="indicator"></span>
                      </label>
                    </th>
                    <th>
                      Report Name
                      <i
                        className={`fa ${sortOrder.column === "reportName" &&
                            sortOrder.order === "asc"
                            ? "fa-sort-amount-asc"
                            : "fa-sort-amount-desc"
                          }`}
                        onClick={() => handleSortChange("reportName")}
                      ></i>
                    </th>
                    <th>
                      Description
                      <i
                        className={`fa ${sortOrder.column === "description" &&
                            sortOrder.order === "asc"
                            ? "fa-sort-amount-asc"
                            : "fa-sort-amount-desc"
                          }`}
                        onClick={() => handleSortChange("description")}
                      ></i>
                    </th>
                    <th>
                      Widgets
                      <i
                        className={`fa ${sortOrder.column === "widgets" &&
                            sortOrder.order === "asc"
                            ? "fa-sort-amount-asc"
                            : "fa-sort-amount-desc"
                          }`}
                        onClick={() => handleSortChange("widgets")}
                      ></i>
                    </th>
                    <th>
                      Created Time
                      <i
                        className={`fa ${sortOrder.column === "createdAt" &&
                            sortOrder.order === "asc"
                            ? "fa-sort-amount-asc"
                            : "fa-sort-amount-desc"
                          }`}
                        onClick={() => handleSortChange("createdAt")}
                      ></i>
                    </th>
                    <th>
                      Status
                      <i
                        className={`fa ${sortOrder.column === "status" &&
                            sortOrder.order === "asc"
                            ? "fa-sort-amount-asc"
                            : "fa-sort-amount-desc"
                          }`}
                        onClick={() => handleSortChange("status")}
                      ></i>
                    </th>
                    <th>Actions</th>
                  </tr>
                  <tr className="search-border">
                    <td></td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="name"
                        placeholder="Search by name"
                        value={searchTerm.name}
                        onChange={handleSearch}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="description"
                        placeholder="Search by description"
                        value={searchTerm.description}
                        onChange={handleSearch}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="widgets"
                        placeholder="Search by widgets"
                        value={searchTerm.widgets}
                        onChange={handleSearch}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="createdAt"
                        placeholder="Search by date"
                        value={searchTerm.createdAt}
                        onChange={handleSearch}
                      />
                    </td>
                    <td>
                      <input
                        className="searchBox smallWidth"
                        type="text"
                        name="status"
                        placeholder="Search by status"
                        value={searchTerm.status}
                        onChange={handleSearch}
                      />
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>
                        <Loader />
                      </td>
                    </tr>
                  ) : paginatedReports.length > 0 ? (
                    paginatedReports.map((report) => (
                      <tr key={report.reportId}>
                        <td>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={selectedReports.includes(
                                report.reportId
                              )}
                              onChange={() =>
                                handleSelectReport(report.reportId)
                              }
                            />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>{report.reportName || "N/A"}</td>
                        <td>{report.description || "N/A"}</td>
                        <td>{report.widgetList?.join(", ") || "N/A"}</td>
                        <td>
                          {new Date(
                            report.createdAt * 1000
                          ).toLocaleDateString()}
                        </td>
                        <td>
                          {report.reportStatus === 1 ? "Success" : "Pending"}
                        </td>
                        <td>
                          <div className="button-group">
                            <button
                              title="View"
                              onClick={() => handleViewReport(report.reportId)}
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                            </button>
                            <button
                              title="Mail"
                              onClick={() =>
                                handleSendEmail(
                                  report.reportId,
                                  report.emailRecipients
                                )
                              }
                            >
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <button
                              className="icon-btn"
                              title="Download"
                              onClick={() =>
                                handleDownload(
                                  report.reportId,
                                  report.reportName
                                )
                              }
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <button
                              title="Delete"
                              disabled={
                                selectedReports.length !== 1 ||
                                selectedReports[0] !== report.reportId
                              }
                              onClick={() =>
                                handleOpenDeleteModal(
                                  filteredReports.find(
                                    (report) =>
                                      report.reportId === selectedReports[0]
                                  )
                                )
                              }
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: "center" }}>
                        No reports available
                      </td>
                    </tr>
                  )}
                  {error && (
                    <tr>
                      <td
                        colSpan="7"
                        style={{ color: "red", textAlign: "center" }}
                      >
                        {error}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pagination-container">
              <div className="pagination-info">
                <p>
                  Showing{" "}
                  {filteredReports.length === 0
                    ? 0
                    : currentPage * pageSize + 1}
                  -
                  {Math.min(
                    (currentPage + 1) * pageSize,
                    filteredReports.length
                  )}{" "}
                  of {filteredReports.length} records
                </p>
              </div>
              <div className="pagination">
                <button
                  onClick={() => handlePageClick(currentPage - 1)}
                  disabled={currentPage === 0}
                >
                  Previous
                </button>
                {getPageNumbers().map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageClick(page)}
                    className={currentPage === page ? "active" : ""}
                  >
                    {page + 1}
                  </button>
                ))}
                <button
                  onClick={() => handlePageClick(currentPage + 1)}
                  disabled={currentPage === totalPages - 1}
                >
                  Next
                </button>
                <button
                  onClick={() => handlePageClick(totalPages - 1)}
                  disabled={currentPage === totalPages - 1}
                >
                  Last Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={closeModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>View Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <iframe
              src={modalData}
              title="Report PDF"
              style={{ width: "100%", height: "500px", border: "none" }}
            ></iframe>
          ) : (
            <p>No report to display.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="text-btn primary-btn" onClick={closeModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div
        className={`modal fade`}
        id="deleteReport"
        tabIndex="-1"
        aria-labelledby="deleteUrlLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure you want to delete?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleDeleteReport();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isModalOpen1}
        onHide={handleCloseDeleteModal}
        centered
        className="delete-confirmation-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reportToDelete ? (
            <p>Are you sure you want to delete the report "<strong>{reportName}</strong>"?</p>
          ) : (
            <p>Are you sure you want to delete the selected reports?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="text-btn primary-btn"
            onClick={handleCloseDeleteModal}
          >
            Cancel
          </button>
          <button className="text-btn primary-btn" onClick={handleDeleteReport}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isConfirmationModalOpen}
        onHide={handleCloseConfirmationModal}
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-title">Confirm Email Send</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <p className="modal-message">
            Are you sure you want to send this report to <strong>{email}</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            className="text-btn primary-btn"
            onClick={handleCloseConfirmationModal}
          >
            Cancel
          </button>
          <button
            className="text-btn primary-btn"
            onClick={handleConfirmSendEmail}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      {isSuccessMessageVisible && (
        <div className="alert alert-success">
          Your report has been sent to {email} successfully!
        </div>
      )}
    </div>
  );
}

export default Reports;
