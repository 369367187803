import React, { useState, useEffect } from "react";
import allApi from "../../../../api/allApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function IpSettings() {
  const [deviceListing, setDeviceListing] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [serialNumber, setSerialNumber] = useState("6LK1002G58727");
  const [formFields, setFormFields] = useState({
    dhcpClientStatus: 1,
    ipAddress: "",
    subnetMask: "",
    gatewayAddress: "",
    primaryDnsAddress: "",
    secondaryDnsAddress: "",
  });

  useEffect(() => {
    getDeviceDetails();
  }, []);

  useEffect(() => {
    if (serialNumber) {
      getIpDetails(serialNumber);
    }
  }, [serialNumber]);

  const getDeviceDetails = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/wireless/deviceList?networkId=6001");
      if (response && response.response) {
        setDeviceListing(response.response);
      }
    } catch (error) {
      console.error("Error fetching devices", error);
    }
  };

  const getIpDetails = async (serialNumber) => {
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/http/ipSettings/${serialNumber}`);
      if (response) {
        setFormFields({
          dhcpClientStatus: response.dhcpClientStatus || 1,
          ipAddress: response.ipAddress || "",
          subnetMask: response.subnetMask || "",
          gatewayAddress: response.gatewayAddress || "",
          primaryDnsAddress: response.primaryDnsAddress || "",
          secondaryDnsAddress: response.secondaryDnsAddress || "",
        });
      }
    } catch (error) {
      console.error("Error fetching IP setting values", error);
    }
  };

  const handleInputChange = (e) => {
    setSerialNumber(e.target.value);
  };

  const inputSettings = async () => {
    try {
      const api = new allApi();
      const payload = {
        SerialNumber: serialNumber,
        dhcpClientStatus: formFields.dhcpClientStatus,
        ipAddr: formFields.ipAddress,
        netmaskAddr: formFields.subnetMask,
        gatewayAddr: formFields.gatewayAddress,
        priDnsAddr: formFields.primaryDnsAddress,
        sndDnsAddr: formFields.secondaryDnsAddress,
      };
      const response = await api.postRequest("api/wireless/ipSettings?networkId=6001", "", payload);
      if (response) {
        setShowSuccessPopup(true);
        //console.log("IP settings set successfully", response);
      }
    } catch (error) {
      console.error("Error setting IP settings", error);
    }
  };

  const handleFieldChange = (field, value) => {
    setFormFields((prev) => ({ ...prev, [field]: value }));
  };

  const handleDhcpToggle = () => {
    setFormFields((prev) => ({
      ...prev,
      dhcpClientStatus: prev.dhcpClientStatus === 1 ? 0 : 1,
    }));
  };

  const handleCancelPopUp = () => {
    setShowSuccessPopup(false);
  };

  return (
    <div>
      <div className="tab-form w-scroll">
        <div className="col-lg-6">
          <label htmlFor="modelName" className="col-form-label">
            Serial Number
          </label>
          <select
            className="form-select"
            name="modelName"
            value={serialNumber}
            onChange={handleInputChange}
          >
            <option value="">Select Serial Number</option>
            {deviceListing?.map((device, index) => (
              <option key={index} value={device.serialNumber}>
                {device.serialNumber}
              </option>
            ))}
          </select>
        </div>
        <div className="row mb-4 mt-3">
          <div className="col-lg-6">
            <div className="switch-box">
              <strong>Assign IP Address Automatically</strong>
              <label className="ice-switch">
                <input
                  type="checkbox"
                  checked={formFields.dhcpClientStatus === 1}
                  onChange={handleDhcpToggle}
                />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4">
            <label htmlFor="ipaddress" className="col-form-label">
              IP Address
            </label>
            <input
              type="text"
              className="form-control"
              value={formFields.ipAddress}
              onChange={(e) => handleFieldChange("ipAddress", e.target.value)}
              disabled={formFields.dhcpClientStatus === 1}
            />
          </div>
          <div className="col-lg-4">
            <label htmlFor="subnetmask" className="col-form-label">
              Subnetmask
            </label>
            <input
              type="text"
              className="form-control"
              value={formFields.subnetMask}
              onChange={(e) => handleFieldChange("subnetMask", e.target.value)}
              disabled={formFields.dhcpClientStatus === 1}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4">
            <label htmlFor="primaryDns" className="col-form-label">
              Primary DNS
            </label>
            <input
              type="text"
              className="form-control"
              value={formFields.primaryDnsAddress}
              onChange={(e) => handleFieldChange("primaryDnsAddress", e.target.value)}
              disabled={formFields.dhcpClientStatus === 1}
            />
          </div>
          <div className="col-lg-4">
            <label htmlFor="secondaryDns" className="col-form-label">
              Secondary DNS
            </label>
            <input
              type="text"
              className="form-control"
              value={formFields.secondaryDnsAddress}
              onChange={(e) => handleFieldChange("secondaryDnsAddress", e.target.value)}
              disabled={formFields.dhcpClientStatus === 1}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4">
            <label htmlFor="gatewayAddress" className="col-form-label">
              Gateway Address
            </label>
            <input
              type="text"
              className="form-control"
              value={formFields.gatewayAddress}
              onChange={(e) => handleFieldChange("gatewayAddress", e.target.value)}
              disabled={formFields.dhcpClientStatus === 1}
            />
          </div>
        </div>
      </div>
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">
          Cancel
        </button>
        <button type="submit" className="text-btn primary-btn" onClick={inputSettings}>
          Save
        </button>
      </div>
      <Modal
        show={showSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Cable Test performed successfully</p>
        <Button onClick={handleCancelPopUp}>
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default IpSettings;
