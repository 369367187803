import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import errorIcon from "../../../../assets/images/error-2.png";
import Loader from "../../common/Loader";

function CableTest() {
  const [deviceListing, setDeviceListing] = useState([]);
  const [serialNumber, setSerialNumber] = useState("");
  const [portId, setPortId] = useState([]);
  const [deviceResult, setDeviceResult] = useState([]);
  const [assignedPorts, setAssignedPorts] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showCableFailurePopup,setShowCableFailurePopup] = useState(false);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    getDeviceDetails();
  }, []);

  const getDeviceDetails = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/vlan/get/allSwitchBasicInfo");
      if (response) {
        setDeviceListing(response);
        console.log("device", response);
      }
    } catch (error) {
      console.error("Error fetching devices", error);
    }
  };

  const cablePortTest = async () => {
    try {
      const api = new allApi();
      const portId = Object.keys(assignedPorts)
        .filter((key) => assignedPorts[key])
        .map(Number);
      const payload = {
        SerialNumber: serialNumber,
        portId: portId,
      };
      const response = await api.postRequest("api/vlan/cable-test", "", payload);
      if (response && response.uuid) {
        const uuid = response.uuid;
        startPolling(uuid);
        setShowPopup(false);
      }
    } catch (error) {
      console.error("Error in cable test", error);
    }
  };
  
  const startPolling = (uuid) => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }
  
    let elapsedTime = 0;
    let timeoutReached = false;
  
    const interval = setInterval(async () => {
      elapsedTime += 1;
      if (elapsedTime >= 20) {
        timeoutReached = true;
      }
  
      const stopPolling = await getCableTestResult(uuid, timeoutReached);
  
      if (stopPolling || timeoutReached) {
        clearInterval(interval);
        setPollingInterval(null);
        console.log("Polling stopped.");
      }
    }, 1000);
  
    setPollingInterval(interval);
  };
  
  const getCableTestResult = async (uuid, timeoutReached) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/vlan/cable/response/${uuid}`);
  
      if (response && response.response_data) {
        const parsedData = JSON.parse(response.response_data);
        setLoading(false);
        setDeviceResult(parsedData);
        setShowSuccessPopup(true);
        return true;
      } else if (timeoutReached && response && response.response_data == null) {
        setLoading(false);
        setShowCableFailurePopup(true);
        return true; // Stop polling since timeout has been reached
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error getting result", error);
      return false;
    }
  };
  

  const handleInputChange = (e) => {
    setSerialNumber(e.target.value);
  };

  const togglePort = (portNumber) => {
    setAssignedPorts((prev) => ({
      ...prev,
      [portNumber]: !prev[portNumber]
    }));
  };

  const handleCancel = () => {
    setSerialNumber("");
    setAssignedPorts({});
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }
  }
  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Untested";
      case 1:
        return "Fail";
      case 2:
        return "Normal";
      case 3:
        return "Open";
      case 4:
        return "Short";
      case 5:
        return "Open Short";
      case 6:
        return "Cross Talk";
      case 7:
        return "No Cable";
      default:
        return "Unknown";
    }
  };

  const handleCancelPopUp = () => {
    setShowSuccessPopup(false);
  }

  const selectedDevice = deviceListing?.portMembers?.find(
    (device) => device.serialNo === serialNumber
  );
  return (
    <div>
      <div className="tab-form">
        <div className="row mb-4">
          <div className="col-lg-12">
            <strong>
              Conduct a test to determine if a cable is working and the fault
              distance.
            </strong>
            <div className="col-lg-6">
              <label htmlFor="modelName" className="col-form-label">
                Serial Number
              </label>
              <select
                className="form-select"
                name="modelName"
                value={serialNumber}
                onChange={handleInputChange}
              >
                <option value="">Select Serial Number</option>
                {deviceListing?.portMembers?.map((device, index) => (
                  <option key={index} value={device.serialNo}>
                    {device.serialNo}
                  </option>
                ))}
              </select>
            </div>

            {selectedDevice && (
              <>
                <p className="mt-4">Select port to test:</p>
                <div className="router-port left mt-3">
                  <div className="mxw-1260">
                    <div className="polygon"></div>
                    <div className="port-box">
                      <ul className={`port${selectedDevice.numberOfPorts}`}>
                        {[...Array(selectedDevice.numberOfPorts)].map((_, index) => {
                          const portNumber = index + 1;
                          return (
                            <li
                              key={portNumber}
                              className={
                                assignedPorts[portNumber] ? "assign" : "unassign"
                              }
                              onClick={() => togglePort(portNumber)}
                            >
                              {portNumber}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {
        loading ? (
          <div>
            <Loader />
          </div>
        ):(
          <div className="tab-form">
          <div className="inc-card-table">
            <table>
              <thead>
                <th>Port</th>
                <th>Test Result</th>
                <th>Fault Distance</th>
              </thead>
              <tbody>
                {deviceResult && deviceResult.length > 0 ? (
                  deviceResult.map((port, index) => (
                    <tr key={index}>
                      <td>{port.portId || "N/A"}</td>
                      <td>{getStatusLabel(port.status)}</td>
                      <td>{port.cableFailureLen || "N/A"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        )
      }   
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline" onClick={handleCancel}>
          Cancel
        </button>
        <button type="submit" className="text-btn primary-btn" onClick={() => setShowPopup(true)}>
          Test Selected Port
        </button>
        <button type="submit" className="text-btn primary-btn">
          Start Another Test
        </button>
      </div>
      {showPopup && (
        <div className="customModal">
          <div className="c-modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Cable Test</h5>
                <button type="button" className="btn-close" onClick={() => setShowPopup(false)}></button>
              </div>
              <div className="modal-body">
                <p>Cable test will disrupt connectivity to all devices on the selected port for a few seconds.
                  Note that if you are performing cable test on the port which connects the switch to the Internet,
                  you will lose Internet connectivity and INC manager will show the device as offline while the test is performed.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline" onClick={() => setShowPopup(false)}>No, don't test</button>
                <button type="submit" className="text-btn primary-btn" onClick={cablePortTest}>Yes, Test the cable</button>
              </div>
            </div>
          </div>
        </div>
      )}
       {showCableFailurePopup && (
              <div className="customModal confirm-modal">
                <div className="modal-dialog modal-dialog-centered small">
                  <div className="modal-content inc-modal">
                    <div className="modal-body">
                      <img src={errorIcon} alt="error" />
                      <h3>The Cable test failed.</h3>
                      <div className="modal-footer">
                        <button className="text-btn primary-btn" onClick={() => setShowCableFailurePopup(false)}>Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
      <Modal
        show={showSuccessPopup}
        onHide={handleCancelPopUp}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Cable Test performed successfully</p>
        <Button onClick={handleCancelPopUp}>
          OK
        </Button>
      </Modal>
      

    </div>
  );
}

export default CableTest;