import React, { useState, useCallback } from "react";
import ReportProfile from "./reportProfile";
import ReportPickWidgets from "./reportPickWidgets";
import ReportReview from "./reportReview";
import allApi from "../../../../api/allApi";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function ReportList() {
  const [currentStep, setCurrentStep] = useState(1);
  const [reportData, setReportData] = useState({
    reportName: "",
    description: "",
    emailRecipients: [],
    timePeriod: "",
    reportType: "Custom Report",
    executionPeriod: "Run Once",
    dataType: [],
    reportList: [],
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const handleStepUpdate = useCallback((stepData) => {
    setReportData((prevData) => ({
      ...prevData,
      ...stepData,
      dataType: stepData.dataType || prevData.dataType,
    }));
  }, []);

  const reportGenerate = async () => {
    try {
      const api = new allApi();
      const formattedDataType = [
        {
          widgets: reportData.dataType.map((widget, index) => ({
            widgetId: widget.widgetId,
            position: widget.position || index + 1,
          })),
        },
      ];
      const payload = {
        name: reportData.reportName,
        description: reportData.description,
        emailRecipients: reportData.emailRecipients,
        templateType: "2",
        timePeriod: reportData.timePeriod,
        executionPeriod: reportData.executionPeriod,
        reportType: reportData.reportType,
        dataType: formattedDataType,
      };
      const response = await api.postRequest(
        "api/report/generate",
        "",
        payload
      );
      if (response && response.report_status === 1) {
        const newReport = {
          reportId: response.reportId,
          reportName: reportData.reportName,
          description: reportData.description,
          widgetList: reportData.dataType.map((widget) => widget.widgetId),
          reportStatus: response.report_status,
          createdAt: Math.floor(Date.now() / 1000),
        };
        setShowSuccessModal(true);
        setReportData((prevData) => ({
          ...prevData,
          reportList: [newReport, ...prevData.reportList],
        }));
      } else {
        alert("Failed to generate report.");
      }
    } catch (error) {
      console.error("Error generating the report:", error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <ReportProfile onUpdate={handleStepUpdate} />;
      case 2:
        return (
          <ReportPickWidgets
            onUpdate={(widgets) => handleStepUpdate({ dataType: widgets })}
          />
        );
      case 3:
        return <ReportReview reportData={reportData} />;
      default:
        return null;
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleCancel = () => {
    setCurrentStep(1);
    setReportData({
      reportName: "",
      description: "",
      emailRecipients: [],
      timePeriod: "",
      reportType: "Custom Report",
      executionPeriod: "Run Once",
      dataType: [],
    });
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep((prev) => prev + 1);
    } else {
      reportGenerate();
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Reports</h3>
          </div>
          <div className="inc-card-body">
            <div className="inc-report">
              <div className="report-tab">
                {[1, 2, 3].map((step) => (
                  <div
                    className={`step ${
                      currentStep === step
                        ? "selected"
                        : currentStep > step
                        ? "completed"
                        : ""
                    }`}
                    key={step}
                    onClick={() => handleStepClick(step)}
                  >
                    <div
                      className="s-text"
                      style={{ cursor: "pointer", color: "purple" }}
                    >
                      <span>STEP {step}</span>
                      <strong>
                        {step === 1 && "Report Profile"}
                        {step === 2 && "Pick Widgets"}
                        {step === 3 && "Review"}
                      </strong>
                    </div>
                    <div className="s-dot">
                      <i
                        className={`fa ${
                          currentStep >= step ? "fa-check" : "fa-circle"
                        }`}
                        aria-hidden="true"
                      ></i>
                    </div>
                    {step < 3 && (
                      <i className="line">
                        <span></span>
                      </i>
                    )}
                  </div>
                ))}
              </div>
              <div className="report-section">
                <div className="report-container">{renderStep()}</div>
                <div className="report-button">
                  {currentStep > 1 && (
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleBack}
                    >
                      Back
                    </button>
                  )}
                  {currentStep === 1 && (
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-fill"
                    onClick={handleNext}
                  >
                    {currentStep === 3 ? "Submit" : "Next"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="inc-alert index-2000"
      >
        <span className="success-icon">
          <i className="icon done"></i>
        </span>
        <h3>Success</h3>
        <p>Report generated successfully</p>
        <Button
          onClick={() => {
            setShowSuccessModal(false);
            navigate("/allReports");
          }}
        >
          OK
        </Button>
      </Modal>
    </div>
  );
}

export default ReportList;
