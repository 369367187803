import React from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();

  // Split pathname into segments and filter empty segments
  const pathnames = location.pathname.split("/").filter((x) => x);

  // Define labels for breadcrumb paths with parent-child relationships
  const breadcrumbLabels = {
    "device-listing": {
      label: "Device Management",
      parent: null,
    },
    "device-dashboard": {
      label: "Device Dashboard",
      parent: "device-listing",
    },
    "device-settings": {
      label: "Device Setting",
      parent: "device-listing",
    },
    "device-snmp": {
      label: "SNMP Device",
      parent: "device-listing",
    },
    "device-detail": {
      label: "Device Detail",
      parent: "device-listing",
    },
    "alarms": {
      label: "Alerts",
      parent: "device-snmp",
    },
    "wired-config": {
      label: "Wired Dashboard",
      parent: null,
    },
    "ConfigureNetwork": {
      label: "Configure Network",
      parent: "wired-config",
    },
    // Add other paths as needed
  };

  // Function to resolve breadcrumb hierarchy
  const resolveBreadcrumbs = () => {
    const breadcrumbs = [];
    let current = pathnames[pathnames.length - 1];

    while (current) {
      const breadcrumbInfo = breadcrumbLabels[current];
      if (breadcrumbInfo) {
        breadcrumbs.unshift({
          label: breadcrumbInfo.label,
          path: `/${current}`,
        });
        current = breadcrumbInfo.parent; // Navigate to the parent
      } else {
        current = null; // Break if no parent is defined
      }
    }

    return breadcrumbs;
  };

  const breadcrumbs = resolveBreadcrumbs();

  // Filter breadcrumb path to exclude "Device Management" and "Wired Dashboard" on their respective parent pages
  const filteredBreadcrumbs = breadcrumbs.filter((breadcrumb, index) => {
    if (
      (breadcrumb.label === "Device Management" && breadcrumbs.length === 1) ||
      (breadcrumb.label === "Wired Dashboard" && breadcrumbs.length === 1)
    ) {
      return false; // Exclude "Device Management" or "Wired Dashboard" if it's the only breadcrumb
    }
    return true;
  });

  return (
    <nav aria-label="breadcrumb" className="inc-breadcrumb">
      <ol>
        {filteredBreadcrumbs.map((breadcrumb, index) => {
          const isLast = index === filteredBreadcrumbs.length - 1;

          return (
            <li
              className={` ${isLast ? "active" : ""}`}
              key={breadcrumb.path}
              aria-current={isLast ? "page" : undefined}
            >
              {isLast ? (
                breadcrumb.label
              ) : (
                <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
